import axios, { AxiosError } from "axios";
import useSWR, { mutate } from "swr";
import { AddProjectResponse } from "types/api/projects";
import { WorkspaceProject } from "types/workspaces";
import { AddProjectInput } from "types/Projects";
import { useSession } from "next-auth/react";

export const useWorkspaceProjects = (workspaceId: string) => {
  const { data: session } = useSession();

  const baseKey = `/api/workspaces/${workspaceId}/projects`;

  const { data, error } = useSWR(
    session ? baseKey : null,
    () => axios.get<WorkspaceProject[]>(baseKey),
    {
      revalidateOnFocus: true,
    }
  );

  return {
    projects: data?.data,
    isProjectsLoading: !error && !data,
    isProjectsError: error as Error | AxiosError<WorkspaceProject>,
  };
};

export async function addWorkspaceProject(addProjectInput: AddProjectInput) {
  const key = `/api/workspaces/${addProjectInput.workspaceId}/projects`;

  const res = await axios.post<AddProjectResponse>(key, addProjectInput);
  mutate(key);
  return res.data;
}

export async function deleteWorkspaceProject(
  projectUUID: string,
  workspaceId: string
) {
  const key = `/api/workspaces/${workspaceId}/projects`;

  await axios.delete(`${key}/${projectUUID}`);
  mutate(key);
}
