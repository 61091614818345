/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import { BORDER_RADIUS, BREAKPOINT, COLOR } from "constants/styles";
import Typewriter from "typewriter-effect";
import axios from "axios";

const FALLBACK_BLOCK_DATA = {
  time: 1695034666,
  height: 9305036,
  hash: "415765cc572bc4f7bf4d01dbfc37a8278070cf3ef83552bc315ca90987e46a9d",
  slot: 103468375,
  epoch: 437,
  epoch_slot: 47575,
  slot_leader: "pool1859zslu3scj23wxcamw0slkhn80waxwkdl4k8k0asyw7sfa8dv5",
  size: 1132,
  tx_count: 3,
  output: "74734390607",
  fees: "531097",
  block_vrf:
    "vrf_vk1nvd5hkrjwa942twtmwkefhqd8nwnvttfyyhkqs5kk29d7sqgrkvsxnzscd",
  op_cert: "2ce9ccfc1175966ed9190929914954a8089a10daaf98283833f97d5f3badf669",
  op_cert_counter: "1",
  previous_block:
    "8516705db824784505b914562800a0d3a590613252bfbf8fa4b97eea08e29314",
  next_block: null,
  confirmations: 0,
};

export const Terminal = () => {
  const [latestBlock, setLatestBlock] = useState<
    Record<string, unknown> | undefined
  >();

  useEffect(() => {
    if (!latestBlock) {
      axios
        .get("/api/landing")
        .then(response => {
          setLatestBlock(response.data);
        })
        .catch(() => {
          setLatestBlock(FALLBACK_BLOCK_DATA);
        });
    }
  }, [latestBlock]);

  return (
    <>
      <div className="terminal-wrapper">
        <div className="terminal">
          <pre>
            {latestBlock && (
              <Typewriter
                onInit={typewriter => {
                  typewriter
                    .changeDelay(70)
                    .typeString(
                      "> curl https://cardano-mainnet.blockfrost.io/api/v0/blocks/latest"
                    )
                    .typeString("\n")
                    .pasteString(
                      JSON.stringify(latestBlock, undefined, 2),
                      null
                    )
                    .callFunction(() => {
                      document?.querySelector(".Typewriter__cursor")?.remove();
                    })
                    .start();
                }}
              />
            )}
          </pre>
        </div>
      </div>

      <style jsx>{`
        .terminal-wrapper {
          display: flex;
          width: 100%;
          margin-left: 32px;
          justify-content: flex-end;
          pointer-events: none;
        }

        .terminal {
          display: flex;
          font-size: 12px;
          width: 640px;
          height: 410px;
          flex-direction: column;
          background: rgba(33, 33, 57, 0.7);
          color: ${COLOR.TEXT_LIGHT};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          padding: 16px;
        }

        .terminal pre {
          overflow: hidden;
        }

        @media screen and (max-width: ${BREAKPOINT.LG}px) {
          .terminal {
            width: 500px;
            height: 430px;
          }
        }

        @media screen and (max-width: ${BREAKPOINT.MD}px) {
          .showcase {
            flex-direction: column;
          }

          .buttons {
            margin-top: 96px;
          }
          .terminal-wrapper {
            display: none;
          }
        }
      `}</style>
    </>
  );
};
