import { Button } from "components";
import { BORDER_RADIUS, COLOR, FONT_SIZE, BREAKPOINT } from "constants/styles";
import React from "react";

interface CommonProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  variant?: "light" | "dark";
  footnotes?: React.ReactNode;
}

type Props = CommonProps &
  (
    | {
        cta?: never;
        ctaLabel: React.ReactNode;
        ctaHref: string;
      }
    | {
        cta: React.ReactNode;
        ctaLabel?: never;
        ctaHref?: never;
      }
  );

const getBackground = (variant: CommonProps["variant"]) => {
  switch (variant) {
    case "dark":
      return `linear-gradient(
          90deg,
          #dd4664 0%,
          rgba(78, 70, 221, 1) 35%,
          #303030 100%
        )`;
    case "light":
      return `linear-gradient(
        90deg,
        rgba(78, 70, 221, 1) 0%,
        rgba(78, 70, 221, 1) 35%,
        rgba(0, 212, 255, 1) 100%
      )`;
  }
};

export const ShinyBanner = ({
  title,
  subtitle,
  footnotes,
  cta,
  ctaLabel,
  ctaHref,
  variant = "light",
  ...rest
}: Props) => {
  return (
    <>
      <style jsx>{`
        .banner-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: relative;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          background: rgb(78, 70, 221);
          background: ${getBackground(variant)};
          padding: 32px 32px;
        }

        .row {
          display: flex;
          width: 100%;
          align-items: center;
        }

        .glow {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          background: ${COLOR.BG_WHITE};
          opacity: 0.1;
          position: absolute;
          left: -90px;
          top: -90px;
        }

        .content {
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;
        }

        .title {
          font-size: ${FONT_SIZE.SECONDARY};
          font-weight: 500;
          color: ${COLOR.TEXT_LIGHT};
        }

        .subtitle {
          font-size: ${FONT_SIZE.XL};
          font-weight: 600;
          color: ${COLOR.TEXT_LIGHT};
        }

        .footnotes {
          font-size: ${FONT_SIZE.XS};
          font-weight: 400;
          color: ${COLOR.TEXT_LIGHT};
          margin-top: 16px;
        }

        .actions {
          display: flex;
          margin-left: 30px;
          margin-top: 20px; // offset is the height of title div because we want to find center of subtitle
          justify-content: flex-end;
        }

        .cta-link {
          display: flex;
          justify-content: center;
        }

        @media screen and (max-width: ${BREAKPOINT.XS}px) {
          .row {
            flex-direction: column;
          }
        }
      `}</style>
      <div className="banner-wrapper" {...rest}>
        <div className="glow"></div>
        <div className="row">
          <div className="content">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
          </div>
          <div className="actions">
            {cta ? (
              cta
            ) : (
              <a
                href={ctaHref}
                target="_blank"
                rel="noreferrer"
                className="cta-link"
              >
                <Button
                  type="button"
                  uppercase
                  style={{ whiteSpace: "nowrap" }}
                >
                  {ctaLabel}
                </Button>
              </a>
            )}
          </div>
        </div>
        {footnotes && <div className="footnotes">{footnotes}</div>}
      </div>
    </>
  );
};

export type { CommonProps as ShinyBannerProps };
