/* eslint-disable @next/next/no-img-element */
import { Button } from "components/UI/Button";
import { Section } from "components/UI/Section";
import React from "react";

function FAQ() {
  return (
    <Section
      id="Contact"
      title="Didn't find what you're looking for?"
      background="dark"
      style={{
        background: "linear-gradient(#3c3351 0%, #1e1a28 70%)",
      }}
    >
      <style jsx>{`
        .wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 800px;
          margin-top: 24px;
        }
      `}</style>
      <div className="wrapper">
        <a href="mailto:support@blockfrost.io">
          <Button
            variant="primary"
            style={{
              minWidth: "200px",
              fontWeight: 600,
              padding: "12px 24px",
              borderRadius: "25px",
            }}
          >
            Get in Touch
          </Button>
        </a>
      </div>
    </Section>
  );
}

export { FAQ };
