import axios, { AxiosError, AxiosResponse } from "axios";
import { useSession } from "next-auth/react";
import useSWR from "swr";

interface Props {
  workspaceId: string;
  projectUUID: string;
}

export function useWorkspaceProjectStats({ workspaceId, projectUUID }: Props) {
  const { data: session } = useSession();

  const key = `/api/workspaces/${workspaceId}/projects/${projectUUID}/stats`;
  const { data, error } = useSWR<
    AxiosResponse<{ projectRequests: number }>,
    AxiosError<{ projectRequests: number }>
  >(session ? key : null, axios.get, {
    refreshInterval: 260 * 60, // 260 minutes?
  });

  return {
    projectStats: data?.data,
    isProjectRequestsLoading: !error && !data,
    isProjectRequestsError: error,
  };
}
