export const BREAKPOINT = {
  XS: 576,
  SM: 768,
  MD: 992,
  LG: 1200,
  XL: 1400,
} as const;

export const FONT_SIZE = {
  PRIMARY: "14px",
  SECONDARY: "12px",
  XXL: "32px",
  XL: "21px",
  LG: "16px",
  SM: "12px",
  XS: "11px",
  XXS: "10px",
} as const;
export const BORDER_RADIUS = {
  PRIMARY: "6px",
  TOOLTIP: "10px",
  SM: "4px",
  LG: "8px",
  XL: "12px",
} as const;

export const COLOR = {
  // Text
  TEXT_PRIMARY: "#1b2e4b",
  TEXT_SECONDARY: "#748293",
  TEXT_WHITE: "#ffffff",
  TEXT_LIGHT: "#fcfcfc",
  // General
  PRIMARY: "#0168fa",
  PRIMARY_HOVER: "#0158d4",
  PRIMARY_BORDER: "#0168fa",
  PRIMARY_HOVER_BORDER: "#0158d4",
  SECONDARY: "#748293",
  SECONDARY_BORDER: "#748293",
  SECONDARY_HOVER: "#64738f",
  SECONDARY_HOVER_BORDER: "#5f6d88",
  SUCCESS: "#10b759",
  SUCCESS_HOVER: "#218838",
  SUCCESS_HOVER_BORDER: "#1e7e34",
  WARNING: "#f7bb42",
  DANGER: "#dc3545",
  DANGER_HOVER: "#c82333",
  DANGER_HOVER_BORDER: "#bd2130",
  // Background colors
  BG_WHITE: "#fff",
  BG_LIGHT: "#f5f5f5",
  BG_LANDING_LIGHT: "#f8fafe",
  BG_LANDING_DARK: "#1e1a28",
  BG_PRIMARY: "#e5e9f2",
  BG_DISABLED: "#f5f5f5", // disabled input, textarea
  BG_DARK: "#282c34", // code snippets
  BG_DARKER: "#202124", // announcement bg
  BG_FOOTER: "#fafbfc",
  BG_PURPLE: "#7054d3",
  // Borders
  BORDER_PRIMARY: "#c0ccda",
  BORDER_PRIMARY_HOVER: "#8392a5",
} as const;
