export const MAX_CONFIRMATIONS = 5;

export const NUM_OPTIONS = [
  { value: "=", label: "equal to" },
  { value: "!=", label: "not equal to" },
  { value: "<=", label: "less than or equal" },
  { value: "<", label: "less than" },
  { value: ">=", label: "greater than or equal" },
  { value: ">", label: "greater than" },
] as const;

export const STRING_OPTIONS = [
  { value: "=", label: "equal to" },
  { value: "!=", label: "not equal to" },
] as const;

export const TRIGGER_OPTIONS = [
  { value: "transaction", label: "Transaction" },
  { value: "block", label: "Block" },
  { value: "delegation", label: "Delegation" },
  { value: "epoch", label: "Epoch" },
] as const;

const JSONPathOption = {
  value: "jsonPath",
  label: "Custom JSONPath Query",
  operators: NUM_OPTIONS,
  placeholderValue: "Value to match",
};

export const TRIGGER_TYPE_OPTIONS = {
  transaction: [
    {
      value: "recipient",
      label: "recipient",
      operators: STRING_OPTIONS,
      placeholderValue: "Bech32 address or stake address",
    },
    {
      value: "sender",
      label: "sender",
      operators: STRING_OPTIONS,
      placeholderValue: "Bech32 address or stake address",
    },
    { value: "quantity", label: "quantity", operators: NUM_OPTIONS },
    { value: "policyId", label: "policy id", operators: STRING_OPTIONS },
    { value: "fingerprint", label: "fingerprint", operators: STRING_OPTIONS },
    { value: "assetHex", label: "asset hex", operators: STRING_OPTIONS },
    JSONPathOption,
  ],
  block: [
    { value: "poolId", label: "pool id", operators: STRING_OPTIONS },
    { value: "txCount", label: "tx count", operators: NUM_OPTIONS },
    { value: "size", label: "size", operators: NUM_OPTIONS },
    { value: "height", label: "height", operators: NUM_OPTIONS },
    { value: "totalFees", label: "total fees", operators: NUM_OPTIONS },
    { value: "totalOutput", label: "total output", operators: NUM_OPTIONS },
    JSONPathOption,
  ],
  delegation: [
    {
      value: "poolId",
      label: "pool id",
      operators: STRING_OPTIONS,
      placeholderValue: "Bech32 pool ID",
    },
    JSONPathOption,
  ],
  epoch: [
    {
      value: "epoch",
      label: "epoch number",
      operators: NUM_OPTIONS,
    },
    JSONPathOption,
  ],
};

export const ERROR = {
  FIELD_REQUIRED: "The field is required",
  INVALID_URL_FORMAT: "Invalid URL format",
  CONFIRMATIONS_OUT_OF_RANGE: "Enter a number between 0 and 5",
} as const;

export const JSONPATH_QUERY_EXAMPLE = {
  delegation: "$.pool.live_saturation",
  transaction: "$.tx.utxo_count",
  block: "$.epoch",
  epoch: "$.previous_epoch.block_count",
};

export const WEBHOOK_DATA_EXAMPLE = {
  delegation: {
    index: 0,
    cert_index: 0,
    address: "stake1uyhxaawwu3cnmuun5j4jzz8scxg3a6svnmcpuszg8leqqsgkcjlea",
    active_epoch: 329,
    pool: {
      pool_id: "pool1df9rj4n0t3zlpak7xnh4ue6t3yh9zlw7a02w4l8askp77up25rt",
      hex: "6a4a39566f5c45f0f6de34ef5e674b892e517ddeebd4eafcfd8583ef",
      vrf_key:
        "c89955b3d37abfdc4eb0afba63392acbdb5f270ebf94dfabd7a26ae2b270bc7e",
      blocks_minted: 389,
      blocks_epoch: 1,
      live_stake: "5408747245727",
      live_size: 0.00023293805381289983,
      live_saturation: 0.08024733395935683,
      live_delegators: 1013,
      active_stake: "5781876450231",
      active_size: 0.0002485887153677961,
      declared_pledge: "50000000000",
      live_pledge: "61116592101",
      margin_cost: 0.02,
      fixed_cost: "340000000",
      reward_account:
        "stake1u9zzujspwtxg03d4wrnwn49fxzj4c9qqw7dwfqygjmpxsyqqe2m5j",
      owners: ["stake1u9zzujspwtxg03d4wrnwn49fxzj4c9qqw7dwfqygjmpxsyqqe2m5j"],
      registration: [
        "7f6ad53328939498abb67344492f21d24bf2b18e9b084831576c4f2840b373d6",
        "14640889489c8d4c381f005d9911c0f0898b2c2c1e51c4f499e5293166ef9fb8",
        "007f6c6130470083b4a77f8c06a8bac3ef343c070fe7fd77e0d6911dc691e5bc",
        "4c0d483da1198a41dc26595d146ced4e952f5a26533279464b384dddb463596f",
        "2e45dd8b494b37f8b5ba6e44a2836cd80c4031dec65afcee831a1b2e57384aea",
        "a35f9197cb5052675c4a7d20b388f1bea9de169ed32c12ee28801f2ef2c5d56c",
        "c71a91e340055ec5f6e1c0f8a3f76fc91985fb5a95d00dc02d4b26ab583116d9",
        "eff5e6744e826e3bdf930525c6b70f31ffa73c72d1176e43acf76c6bf60acc66",
        "82ec1668a5769c6b4561ff3e75320e506cbd84261d401e8995c146a8d285c518",
        "1beae5d09a17d6d0d11be2ef0bfa44c09bfe0015b15b922dc4398bf98da58edf",
        "3dc21a55d9f4cf18d82aa9f130dd131aaef1a2c1dff24e24bfc0963657a05332",
        "fc0b0ed216662d83e51bace470291de85e766ee4e9adcb4f6f2922c0f96aec6e",
        "cba71ab591f7400650ed657c2341a7b0a54524a3b24a69154e438d0a0c97b9b8",
        "09892faa9635ae10122b3e93b18d061627b4828d77b1412dcc387fc7ca96335f",
      ],
      retirement: [],
    },
  },
  block: {
    time: 1650013853,
    height: 7126256,
    hash: "f49521b67b440e5030adf124aee8f88881b7682ba07acf06c2781405b0f806a4",
    slot: 58447562,
    epoch: 332,
    epoch_slot: 386762,
    slot_leader: "pool1njjr0zn7uvydjy8067nprgwlyxqnznp9wgllfnag24nycgkda25",
    size: 34617,
    tx_count: 13,
    output: "13403118309871",
    fees: "4986390",
    block_vrf:
      "vrf_vk197w95j9alkwt8l4g7xkccknhn4pqwx65c5saxnn5ej3cpmps72msgpw69d",
    previous_block:
      "9e3f5bfc9f0be44cf6e14db9ed5f1efb6b637baff0ea1740bb6711786c724915",
    next_block: null,
    confirmations: 0,
  },
  transaction: {
    tx: {
      hash: "83bc3b32a435a478235d85c50b0e748aa3994997e8eca678e817e06e58f06518",
      block: "3eb0fc9d9ac01f9c6c1d4b31e27f7301fb84d953fab2b6dae5dbbb7926db65b1",
      block_height: 7298884,
      block_time: 1653649210,
      slot: 62082919,
      index: 6,
      output_amount: [
        {
          unit: "lovelace",
          quantity: "4935230005",
        },
        {
          unit: "29d222ce763455e3d7a09a665ce554f00ac89d2e99a1a83d267170c64d494e",
          quantity: "2704051172",
        },
        {
          unit: "29d222ce763455e3d7a09a665ce554f00ac89d2e99a1a83d267170c64d494e",
          quantity: "54975362699733",
        },
        {
          unit: "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235484f534b59",
          quantity: "177615485568",
        },
        {
          unit: "b34b3ea80060ace9427bda98690a73d33840e27aaa8d6edb7f0c757a634e455441",
          quantity: "571646",
        },
        {
          unit: "d195ca7db29f0f13a00cac7fca70426ff60bad4e1e87d3757fae84844d617374657243686566",
          quantity: "1",
        },
        {
          unit: "edfd7a1d77bcb8b884c474bdc92a16002d1fb720e454fa6e993444794e5458",
          quantity: "413744657576",
        },
      ],
      fees: "311189",
      deposit: "0",
      size: 2152,
      invalid_before: null,
      invalid_hereafter: null,
      utxo_count: 6,
      withdrawal_count: 0,
      mir_cert_count: 0,
      delegation_count: 0,
      stake_cert_count: 0,
      pool_update_count: 0,
      pool_retire_count: 0,
      asset_mint_or_burn_count: 1,
      redeemer_count: 1,
      valid_contract: true,
    },
    inputs: [
      {
        address:
          "addr1qxkmr0m22xeqludcg5rjdmecjxasu9fat0680qehtcsnftaadgykewa9ufvegeuca9yyq03d9v7ea2y2zthgu7hfgjtsddp6gr",
        amount: [
          {
            unit: "lovelace",
            quantity: "4931403310",
          },
        ],
        tx_hash:
          "180f849bc64d62b0af6d5262de24cb63be8cc1005fb53bc4d10a2a7684eb001b",
        output_index: 0,
        collateral: false,
        data_hash: null,
      },
      {
        address:
          "addr1qxkmr0m22xeqludcg5rjdmecjxasu9fat0680qehtcsnftaadgykewa9ufvegeuca9yyq03d9v7ea2y2zthgu7hfgjtsddp6gr",
        amount: [
          {
            unit: "lovelace",
            quantity: "2137884",
          },
          {
            unit: "29d222ce763455e3d7a09a665ce554f00ac89d2e99a1a83d267170c64d494e",
            quantity: "54978066750905",
          },
          {
            unit: "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235484f534b59",
            quantity: "177615485568",
          },
          {
            unit: "b34b3ea80060ace9427bda98690a73d33840e27aaa8d6edb7f0c757a634e455441",
            quantity: "571646",
          },
          {
            unit: "d195ca7db29f0f13a00cac7fca70426ff60bad4e1e87d3757fae84844d617374657243686566",
            quantity: "1",
          },
          {
            unit: "edfd7a1d77bcb8b884c474bdc92a16002d1fb720e454fa6e993444794e5458",
            quantity: "413744657576",
          },
        ],
        tx_hash:
          "180f849bc64d62b0af6d5262de24cb63be8cc1005fb53bc4d10a2a7684eb001b",
        output_index: 3,
        collateral: false,
        data_hash: null,
      },
      {
        address: "addr1wxvd7wcq59gqljmhm2s9yp2slvygljfr8xtc3wykx7uaukgt8lqh6",
        amount: [
          {
            unit: "lovelace",
            quantity: "2000000",
          },
          {
            unit: "d195ca7db29f0f13a00cac7fca70426ff60bad4e1e87d3757fae848468764d494e",
            quantity: "2704051172",
          },
        ],
        tx_hash:
          "ee21ff6bf92117b56198bd3c4a08df3cb953f50381453d6d4af5daec70ac63ba",
        output_index: 0,
        collateral: false,
        data_hash:
          "9dcc08769d4b0880d392bd850561cc6d286d7abfb0a98222cbf3a260d504be0a",
      },
      {
        address:
          "addr1qxkmr0m22xeqludcg5rjdmecjxasu9fat0680qehtcsnftaadgykewa9ufvegeuca9yyq03d9v7ea2y2zthgu7hfgjtsddp6gr",
        amount: [
          {
            unit: "lovelace",
            quantity: "4931403310",
          },
        ],
        tx_hash:
          "180f849bc64d62b0af6d5262de24cb63be8cc1005fb53bc4d10a2a7684eb001b",
        output_index: 0,
        collateral: true,
        data_hash: null,
      },
    ],
    outputs: [
      {
        address:
          "addr1qxkmr0m22xeqludcg5rjdmecjxasu9fat0680qehtcsnftaadgykewa9ufvegeuca9yyq03d9v7ea2y2zthgu7hfgjtsddp6gr",
        amount: [
          {
            unit: "lovelace",
            quantity: "4931747323",
          },
        ],
        output_index: 0,
        data_hash: null,
      },
      {
        address:
          "addr1q9w6c97u6dp6wcmr8khkfad5399q0x5ww0dsudkk8gt0la6kct5ywljahekgwkkerteaayyr7r35j3rw0n3247nka0xq58kc7k",
        amount: [
          {
            unit: "lovelace",
            quantity: "1344798",
          },
          {
            unit: "29d222ce763455e3d7a09a665ce554f00ac89d2e99a1a83d267170c64d494e",
            quantity: "2704051172",
          },
        ],
        output_index: 1,
        data_hash: null,
      },
      {
        address:
          "addr1qxkmr0m22xeqludcg5rjdmecjxasu9fat0680qehtcsnftaadgykewa9ufvegeuca9yyq03d9v7ea2y2zthgu7hfgjtsddp6gr",
        amount: [
          {
            unit: "lovelace",
            quantity: "2137884",
          },
          {
            unit: "29d222ce763455e3d7a09a665ce554f00ac89d2e99a1a83d267170c64d494e",
            quantity: "54975362699733",
          },
          {
            unit: "a0028f350aaabe0545fdcb56b039bfb08e4bb4d8c4d7c3c7d481c235484f534b59",
            quantity: "177615485568",
          },
          {
            unit: "b34b3ea80060ace9427bda98690a73d33840e27aaa8d6edb7f0c757a634e455441",
            quantity: "571646",
          },
          {
            unit: "d195ca7db29f0f13a00cac7fca70426ff60bad4e1e87d3757fae84844d617374657243686566",
            quantity: "1",
          },
          {
            unit: "edfd7a1d77bcb8b884c474bdc92a16002d1fb720e454fa6e993444794e5458",
            quantity: "413744657576",
          },
        ],
        output_index: 2,
        data_hash: null,
      },
    ],
  },
  epoch: {
    previous_epoch: {
      epoch: 342,
      start_time: 1653947091,
      end_time: 1654379091,
      first_block_time: 1653947172,
      last_block_time: 1654379048,
      block_count: 20990,
      tx_count: 470464,
      output: "117853542835571922",
      fees: "163696811862",
      active_stake: "24660991673767486",
    },
    current_epoch: {
      epoch: 343,
      start_time: 1654379091,
      end_time: 1654811091,
    },
  },
};
