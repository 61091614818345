import { DashboardStats } from "../DashboardStats";
import React from "react";

interface Props {
  workspaceId: string;
  isCreator: boolean;
}

export const DashboardHeader = ({ workspaceId, isCreator }: Props) => {
  return (
    <div className="dashboard-header">
      <div className="col">
        <DashboardStats workspaceId={workspaceId} isCreator={isCreator} />
      </div>
      <style jsx>{`
        .dashboard-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
};
