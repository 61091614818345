import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Select from "react-select";
import { ERROR, TRIGGER_TYPE_OPTIONS } from "constants/webhooks";
import { WebhookConditionForm, WebhookFormDataRaw } from "types/webhooks";
import { JSONPathEditor } from "./JSONPathEditor";
import { Button } from "components";
import { COLOR, FONT_SIZE } from "constants/styles";
import { RemoveItemBtn } from "components/UI/RemoveItemBtn";

interface Props {
  conditions: WebhookConditionForm[] | undefined;
}

export const ConditionsEditor = ({ conditions }: Props) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<WebhookFormDataRaw>();

  const { fields, append, remove } = useFieldArray({
    name: "conditions",
    control,
  });

  // const watchConditions = useWatch<WebhookFormDataRaw>({ name: "conditions" });
  const watchConditions = watch("conditions");
  const triggerWatch = watch("trigger", {
    value: "transaction",
    label: "Transaction",
  });
  const trigger = triggerWatch.value;

  return (
    <>
      <style jsx>{`
        .conditions-wrapper {
          margin-top: 10px;
          margin-bottom: 16px;
        }

        .b-row {
          display: grid;
          width: 100%;
          grid-template-columns: 3fr 3fr 4fr 2fr;
          gap: 32px;
          margin-bottom: 24px;
        }

        .b-row {
        }

        .center-row {
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .error {
          display: flex;
          color: ${COLOR.DANGER};
          margin-top: 2px;
        }

        .sub-description {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
          margin-bottom: 1.5rem;
        }
      `}</style>
      <div className="conditions-wrapper">
        {fields.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              {/* Condition type (field to match against) */}
              <div className="b-row">
                <div className="b-col">
                  <Controller
                    control={control}
                    name={`conditions.${index}.type`}
                    defaultValue={
                      conditions?.[index]
                        ? conditions[index].type
                        : TRIGGER_TYPE_OPTIONS[trigger][0]
                    }
                    render={({ field }) => (
                      <Select
                        styles={{
                          menu: provided => ({ ...provided, zIndex: 5 }),
                        }}
                        {...field}
                        options={TRIGGER_TYPE_OPTIONS[trigger]}
                      />
                    )}
                  />
                </div>

                {/* Condition operator (<, =, !=,...) */}
                <div className="b-col">
                  <Controller
                    control={control}
                    name={`conditions.${index}.operator`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        // if conditions is already filled then use that as a default value, otherwise take first operator for given condition trigger type
                        defaultValue={
                          conditions?.[index]
                            ? conditions[index].operator
                            : TRIGGER_TYPE_OPTIONS[trigger][0].operators[0]
                        }
                        options={
                          TRIGGER_TYPE_OPTIONS[trigger].find(
                            t => t.value === watchConditions?.[index].type.value
                          )?.operators ?? []
                          // watchConditions?.[index]?.["type"].operators ?? []
                        }
                      />
                    )}
                  />
                </div>

                {/* Condition value (string that will be matched) */}
                <div className="b-col">
                  <Controller
                    control={control}
                    name={`conditions.${index}.value`}
                    rules={{ required: ERROR.FIELD_REQUIRED }}
                    defaultValue={
                      conditions?.[index] ? conditions[index].value : ""
                    }
                    render={({ field }) => (
                      <input
                        autoComplete="off"
                        placeholder={
                          TRIGGER_TYPE_OPTIONS[trigger].find(
                            t => t.value === watchConditions?.[index].type.value
                            // @ts-expect-error no idea
                          )?.placeholderValue ?? ""

                          // watchConditions?.[index].type.placeholderValue
                        }
                        className={
                          errors?.conditions?.[index]
                            ? "is-invalid form-control"
                            : "form-control"
                        }
                        {...field}
                      />
                    )}
                  />
                  {errors?.conditions?.[index]?.value && (
                    <span className="error">
                      {errors?.conditions[index]?.value?.message}
                    </span>
                  )}
                </div>

                <div className="b-col">
                  {/* Remove button */}
                  <RemoveItemBtn
                    title="Remove condition"
                    onClick={() => {
                      remove(index);
                    }}
                  />
                </div>
              </div>

              {/* JSONPath Query Editor */}
              {watchConditions?.[index]?.type.value === "jsonPath" && (
                <JSONPathEditor conditions={conditions} index={index} />
              )}
            </React.Fragment>
          );
        })}

        <div className="center-row">
          <Button
            variant="primary"
            size="sm"
            disabled={fields.length >= 5}
            onClick={() =>
              append({
                type: TRIGGER_TYPE_OPTIONS[trigger][0],
                operator: TRIGGER_TYPE_OPTIONS[trigger][0].operators[0],
                value: "",
              })
            }
          >
            + Add condition
          </Button>
        </div>
      </div>
    </>
  );
};
