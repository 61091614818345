import React from "react";
import { routes } from "utils/routes";
import Link from "next/link";
import { IoCubeOutline } from "react-icons/io5";
import { Button } from "components";
import { FONT_SIZE } from "constants/styles";
import { useSession } from "next-auth/react";

export const BuildAppsNowButton = () => {
  const { data: session } = useSession();

  return (
    <Link href={session ? routes.dashboard : routes.authSignIn} passHref>
      <Button icon={<IoCubeOutline />}>
        <span className="btn-build-label">Dashboard</span>
      </Button>
      <style jsx>{`
        .icon-wrapper {
          display: flex;
          align-items: center;
        }
        .btn-build-label {
          letter-spacing: 1px;
          font-size: ${FONT_SIZE.XS};
          font-weight: 600;
          text-transform: uppercase;
        }
      `}</style>
    </Link>
  );
};
