import { AddProjectForm, ErrorComponent, RowPlaceholder } from "components";
import { ProjectItem } from "./ProjectItem";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { routes } from "utils/routes";
import { useRouter } from "next/router";
import { ProjectListHeader } from "../ProjectListHeader";
import {
  addWorkspaceProject,
  useWorkspaceProjects,
} from "hooks/useWorkspaceProjects";
import { useWorkspaceQuota } from "hooks/useWorkspaceQuota";
import { WorkspaceItem } from "types/workspaces";
import { ROLE_PERMISSIONS } from "constants/rbac";
import { useWorkspaceBilling } from "hooks/useWorkspaceBilling";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface Props {
  workspace: WorkspaceItem;
}

function Projects({ workspace }: Props) {
  const workspaceId = workspace.id;
  const router = useRouter();
  const [addProjectFormOpened, setAddProjectFormOpened] = useState(false);

  const { projects, isProjectsError, isProjectsLoading } =
    useWorkspaceProjects(workspaceId);
  const { quota } = useWorkspaceQuota(workspaceId);

  const { billing } = useWorkspaceBilling(workspaceId);

  if (isProjectsError) {
    return <ErrorComponent error={isProjectsError} />;
  }

  if (isProjectsLoading || !projects || !quota) {
    return (
      <>
        <ProjectListHeader
          heading="Projects"
          resource="project"
          workspaceId={workspaceId}
          reachedSubscriptionLimit={false}
          insufficientPermissions={false}
          isUserSubscription={false}
          ctaButton={{
            isDisabled: true,
            label: "Add Project",
            "data-cy": "add-project",
            onClick: () => setAddProjectFormOpened(true),
          }}
          remainingQuota={<Skeleton height={15} width={80} />}
        />
        <Skeleton
          count={3}
          width="100%"
          height={77}
          style={{ lineHeight: "8px" }}
        />
      </>
    );
  }

  const permissionToAddProject =
    !!ROLE_PERMISSIONS.projects.add[workspace.role_name];

  const isDisabled =
    projects && billing
      ? addProjectFormOpened ||
        quota.subscription.status === "incomplete" ||
        quota.subscription.status === "incomplete_expired" ||
        billing.delinquent ||
        quota.used.subscription.projectCount >= quota.quota.projectCount ||
        !permissionToAddProject
      : true;

  return (
    <>
      <ProjectListHeader
        heading="Projects"
        resource="project"
        reachedSubscriptionLimit={
          quota.used.subscription.projectCount >= quota.quota.projectCount
        }
        ctaButton={{
          isDisabled: isDisabled,
          label: "Add Project",
          "data-cy": "add-project",
          onClick: () => setAddProjectFormOpened(true),
        }}
        isUserSubscription={workspace.is_creator}
        insufficientPermissions={!permissionToAddProject}
        workspaceId={workspace.id}
        remainingQuota={
          <>
            {projects &&
              quota.quota.projectCount - quota.used.subscription.projectCount}
            <span>&nbsp;project</span>
            <span>
              <span>
                {projects &&
                quota.quota.projectCount -
                  quota.used.subscription.projectCount ===
                  1
                  ? ""
                  : "s"}
              </span>
              <span>&nbsp;left</span>
            </span>
          </>
        }
      />

      {!addProjectFormOpened && projects.length === 0 && (
        <RowPlaceholder data-cy="no-projects-placeholder">
          Start by adding your first project
        </RowPlaceholder>
      )}

      {addProjectFormOpened && (
        <AddProjectForm
          onCancel={() => setAddProjectFormOpened(false)}
          onSave={async data => {
            try {
              const addedProject = await addWorkspaceProject({
                projectName: data.projectName,
                networkId: data.network.value,
                workspaceId: workspaceId,
              });
              setAddProjectFormOpened(false);
              router.push(routes.dashboardProject(addedProject.uuid));
            } catch (error) {
              if (error instanceof AxiosError) {
                toast.error(error.response?.data.error);
              } else if (error instanceof Error) {
                toast.error(error.message);
              } else {
                console.error(error);
              }
            }
          }}
        />
      )}

      {!isProjectsError &&
        projects.map(project => {
          return (
            <ProjectItem
              key={project.id}
              project={project}
              onClick={() => router.push(routes.dashboardProject(project.uuid))}
            />
          );
        })}
    </>
  );
}

export { Projects };
