import React from "react";
import Image from "next/image";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { Button } from "components";

interface Props {
  rpcUrl: string;
  networkId?: number;
}

function MetamaskButton({ rpcUrl, networkId }: Props) {
  const provider = window.ethereum;

  const mainnetConfig = [
    {
      chainId: "0x7D1",
      chainName: "Milkomeda Cardano Mainnet",
      rpcUrls: [rpcUrl],
      blockExplorerUrls: ["https://rpc.c1.milkomeda.com:4000"],
      nativeCurrency: {
        symbol: "mADA",
        decimals: 18,
      },
    },
  ];

  const testnetConfig = [
    {
      chainId: "0x30DA5",
      chainName: "Milkomeda Cardano Testnet",
      rpcUrls: [rpcUrl],
      blockExplorerUrls: [
        "https://explorer-devnet-cardano-evm.c1.milkomeda.com",
      ],
      nativeCurrency: {
        symbol: "mTADA",
        decimals: 18,
      },
    },
  ];

  const ButtonComponent = (
    <Button
      variant="outline-primary"
      disabled={!provider}
      style={{ display: "flex" }}
      onClick={async () => {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: networkId === 3 ? mainnetConfig : testnetConfig,
          });
        } catch (error) {
          console.error(error);
          toast.error(
            "Error while adding milkomeda to MetaMask. Please contact our support."
          );
        }
      }}
    >
      <div className="text">Setup MetaMask</div>
      <Image alt="metamask" src="/images/metamask.svg" width={20} height={20} />
      <style jsx>{`
        .text {
          margin-right: 10px;
        }
      `}</style>
    </Button>
  );

  if (!provider) {
    return (
      <Tippy
        content={<span>Please install the MetaMask browser extension</span>}
      >
        <span>{ButtonComponent}</span>
      </Tippy>
    );
  }

  return ButtonComponent;
}

export { MetamaskButton };
