import React from "react";
import { signIn } from "next-auth/react";
import { Button } from "components";

export default function GitLabSignIn({
  callbackUrl,
}: {
  callbackUrl?: string;
}) {
  return (
    <Button
      fullWidth
      variant="outline-primary"
      onClick={() => signIn("gitlab", { callbackUrl })}
    >
      Sign In with GitLab
    </Button>
  );
}

export { GitLabSignIn };
