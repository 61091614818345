import React from "react";
import { VscGraphLine } from "react-icons/vsc";
import { GiSandsOfTime } from "react-icons/gi";
import Link from "next/link";
import { Button, Card } from "components";
import { routes } from "utils/routes";
import { COLOR, FONT_SIZE } from "constants/styles";

interface Props {
  isLocked: boolean | undefined;
  name: string;
}

function LockedOrEmptyBox({ isLocked, name }: Props) {
  return (
    <Card noMargin>
      <style jsx>{`
        .title {
          font-size: ${FONT_SIZE.XS};
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-weight: 600;
          color: ${COLOR.TEXT_PRIMARY};
          margin-bottom: 8px;
        }

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
          justify-content: center;
        }
        .text {
          margin-top: 10px;
          text-align: center;
          max-width: 400px;
        }
        .card-min {
          display: flex;
          height: 100%;
          min-height: 400px;
          flex-direction: column;
        }
        .no-data {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;
          justify-content: center;
        }
        .waiting {
          margin-top: 10px;
        }
        .description {
          margin-bottom: 10px;
        }
        .upgrade-text {
          font-weight: 500;
          text-align: center;
          margin-top: 10px;
        }
      `}</style>
      <div className="card-min">
        <span className="title">
          {name === "countries" && <span>Countries</span>}
          {name === "ips" && <span>IP addresses</span>}
          {name === "requests" && <span>Requests</span>}
          {name === "browsers" && <span>Browsers</span>}
          {name === "locations" && <span>Locations</span>}
        </span>
        <div className="content">
          {isLocked && (
            <>
              <VscGraphLine size={50} color={COLOR.TEXT_PRIMARY} />
              <div className="upgrade-text" data-cy="stats-upgrade-offer">
                <div className="description">See more detailed stats</div>
                <Link
                  href={routes.dashboardSubscription}
                  passHref
                  legacyBehavior
                >
                  <a>
                    <Button variant="secondary">
                      Upgrade to DEVELOPER Plan
                    </Button>
                  </a>
                </Link>
              </div>
            </>
          )}
          {!isLocked && (
            <div className="no-data" data-cy="stats-no-data">
              <GiSandsOfTime size={50} />
              <h6 className="waiting">Waiting for the first request...</h6>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export { LockedOrEmptyBox };
