import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import React from "react";
import { Spinner } from "../Spinner";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "primary"
    | "secondary"
    | "secondary-alt" // old btn-white
    | "success"
    | "danger"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-danger"
    | "animated-gradient";
  fullWidth?: boolean;
  uppercase?: boolean;
  loading?: boolean;
  size?: "normal" | "sm" | "xs";
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const getVariantColors = (variant: Props["variant"]) => {
  switch (variant) {
    case "primary":
    default:
      return {
        fg: COLOR.TEXT_LIGHT,
        bg: COLOR.PRIMARY,
        border: COLOR.PRIMARY,
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: COLOR.PRIMARY_HOVER,
        borderHover: COLOR.PRIMARY_HOVER_BORDER,
      };
    case "outline-primary":
      return {
        fg: COLOR.PRIMARY,
        bg: "transparent",
        border: COLOR.PRIMARY,
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: COLOR.PRIMARY,
        borderHover: COLOR.PRIMARY,
      };
    case "danger":
      return {
        fg: COLOR.TEXT_LIGHT,
        bg: COLOR.DANGER,
        border: COLOR.DANGER,
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: COLOR.DANGER_HOVER,
        borderHover: COLOR.DANGER_HOVER_BORDER,
      };
    case "outline-danger":
      return {
        fg: COLOR.DANGER,
        bg: "transparent",
        border: COLOR.DANGER,
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: COLOR.DANGER,
        borderHover: COLOR.DANGER,
      };
    case "success":
      return {
        fg: COLOR.TEXT_LIGHT,
        bg: COLOR.SUCCESS,
        border: COLOR.SUCCESS,
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: COLOR.SUCCESS_HOVER,
        borderHover: COLOR.SUCCESS_HOVER_BORDER,
      };
    case "secondary":
      return {
        fg: COLOR.TEXT_LIGHT,
        bg: COLOR.SECONDARY,
        border: COLOR.SECONDARY_BORDER,
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: COLOR.SECONDARY_HOVER,
        borderHover: COLOR.SECONDARY_HOVER_BORDER,
      };
    case "outline-secondary":
      return {
        fg: COLOR.SECONDARY,
        bg: "transparent",
        border: COLOR.SECONDARY,
        fgHover: COLOR.BG_WHITE,
        bgHover: COLOR.SECONDARY,
        borderHover: COLOR.SECONDARY,
      };
    case "secondary-alt":
      return {
        fg: COLOR.SECONDARY,
        bg: COLOR.BG_WHITE,
        border: COLOR.BORDER_PRIMARY,
        fgHover: COLOR.TEXT_PRIMARY,
        bgHover: COLOR.BG_WHITE,
        borderHover: COLOR.BORDER_PRIMARY_HOVER,
      };
    case "link":
      return {
        fg: COLOR.PRIMARY,
        bg: "transparent",
        border: "transparent",
        fgHover: COLOR.PRIMARY_HOVER,
        bgHover: "transparent",
        borderHover: "transparent",
      };
    case "animated-gradient":
      return {
        fg: COLOR.TEXT_LIGHT,
        bg: `linear-gradient(
          -45deg,
          #ffa63d,
          #ff3d77,
          #338aff,
          #3cf0c5
        )`,
        border: "transparent",
        fgHover: COLOR.TEXT_LIGHT,
        bgHover: `linear-gradient(
          -45deg,
          #ffa63d,
          #ff3d77,
          #338aff,
          #3cf0c5
        )`,
        borderHover: "transparent",
      };
  }
};

const getSize = (size: Props["size"]) => {
  switch (size) {
    case "normal":
    default:
      return {
        fontSize: "14px",
        lineHeight: 1.5,
        padding: "0.46875rem 0.9375rem",
      } as const;
    case "sm":
      return {
        fontSize: "13px",
        lineHeight: 1.5,
        padding: "6px 16px",
      } as const;
    case "xs":
      return {
        fontSize: "12px",
        lineHeight: 1.5,
        padding: "4px 16px",
      } as const;
  }
};

export const Button = ({
  variant = "primary",
  size = "normal",
  icon,
  fullWidth,
  loading,
  disabled,
  uppercase,
  onClick,
  children,
  ...rest
}: Props) => {
  const colors = getVariantColors(variant);
  const sizes = getSize(size);
  return (
    <button
      className={`b-btn ${fullWidth ? "full-width" : ""} ${
        uppercase ? `b-btn-uppercase-${size}` : ""
      } ${variant === "animated-gradient" ? `animated-gradient` : ""}`}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      type="button"
      {...rest}
    >
      <div className="btn-content">
        <div className="btn-addons">
          {/* Note: conditional spinner needs to be wrapped in another div otherwise gg translate throws insertBefore error */}
          {loading && (
            <div className="icon-wrapper">
              <Spinner size="xs" role="status" aria-hidden="true" />
            </div>
          )}
        </div>
        {!loading && icon && <div className="icon-wrapper">{icon}</div>}
        {children}
      </div>
      <style jsx>{`
        .b-btn {
          display: inline-block;
          font-weight: 400;
          color: ${colors.fg};
          text-align: center;
          user-select: none;
          background: ${colors.bg};
          border: 1px solid ${colors.border};
          padding: ${sizes.padding};
          font-size: ${sizes.fontSize};
          line-height: ${sizes.lineHeight};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          cursor: ${disabled ? "auto" : "pointer"};
        }

        .animated-gradient {
          background-size: 600%;
          animation: gradient-animation 6s linear infinite;
        }

        @keyframes gradient-animation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .b-btn + .b-btn {
          margin-top: ${fullWidth ? "0.5rem" : 0};
        }

        .b-btn-uppercase-normal,
        .b-btn-uppercase-sm {
          font-size: ${FONT_SIZE.XS};

          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.5px;
          padding: 6px 15px;
          line-height: 1.75;
        }

        .b-btn-uppercase-xs {
          font-size: ${FONT_SIZE.XS};
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        .b-btn:disabled {
          opacity: 0.65;
          pointer-events: none;
          animation-play-state: paused;
        }

        .btn-content {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
        }

        .full-width {
          display: block;
          width: 100%;
        }

        .b-btn:hover,
        .b-btn:active {
          color: ${colors.fgHover};
          background-color: ${colors.bgHover};
          border: 1px solid ${colors.borderHover};
          animation-play-state: paused;
          /* transform: scale(1.02);
          transition: all 0.2s ease-in-out; */
        }

        .icon-wrapper {
          display: flex;
          margin-right: 0.8ch;
        }
      `}</style>
    </button>
  );
};

export type { Props as ButtonProps };
