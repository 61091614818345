import React from "react";
import { DataProps } from "types/api/projects";
import { LockedOrEmptyBox, Card } from "components";
import Image from "next/image";
import BigNumber from "bignumber.js";
import countriesData from "constants/countries";
import { COLOR, FONT_SIZE } from "constants/styles";

function Box(
  props: DataProps & {
    total?: string;
    isLocked?: boolean;
    lockedTitle: string;
  }
) {
  if (!props.data) return null;

  if (props.isLocked || props.data.length === 0) {
    return <LockedOrEmptyBox isLocked={props.isLocked} name={props.name} />;
  }

  return (
    <Card noMargin>
      <div className="card-min">
        <style jsx>{`
          .main {
            display: flex;
            flex-direction: column;
          }
          .my-row {
            position: relative;
            display: flex;
            z-index: 5;
            align-items: center;
            min-height: 35px;
            margin-bottom: 3px;
          }
          .line {
            position: absolute;
            height: 100%;
            z-index: 4;
            background: ${COLOR.BG_DISABLED};
          }
          .flag-text {
            padding-left: 10px;
          }
          .card-min {
            min-height: 360px;
          }
          .no-data {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-size: ${FONT_SIZE.PRIMARY};
            height: 100%;
          }
          .head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }
          .left {
            display: flex;
            z-index: 5;
            padding-left: 10px;
          }
          .right {
            flex: 1;
            z-index: 5;
            padding-right: 10px;
            text-align: right;
          }
          .waiting {
            margin-top: 15px;
          }
          .title-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .title {
            font-size: ${FONT_SIZE.XS};
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: 600;
            color: ${COLOR.TEXT_PRIMARY};
            margin-bottom: 8px;
          }

          .top-text {
            color: ${COLOR.TEXT_SECONDARY};
            font-size: ${FONT_SIZE.SECONDARY};
            margin-bottom: 0.5rem;
            font-weight: 500;
          }

          .wrapper {
            height: 100%;
          }

          .subtitle-header {
            margin-top: 20px;
          }

          .tx-small {
            font-size: ${FONT_SIZE.SECONDARY};
            font-weight: 500;
          }

          .tx-medium {
            font-weight: 500;
          }
        `}</style>
        <div className="wrapper">
          <div className="title-header">
            <span className="title">
              {props.name === "countries" && <div>Countries</div>}
              {props.name === "ips" && <div>IP addresses</div>}
              {props.name === "requests" && <div>Requests</div>}
              {props.name === "browsers" && <div>Browsers</div>}
            </span>
            <span className="top-text">TOP 10</span>
          </div>
          <div className="subtitle-header">
            <div className="head">
              <div>
                {props.name === "countries" && (
                  <div className="tx-small">Country</div>
                )}
                {props.name === "ips" && (
                  <div className="tx-small">IP address</div>
                )}
                {props.name === "requests" && (
                  <div className="tx-small">Endpoint</div>
                )}
                {props.name === "browsers" && (
                  <div className="tx-small">Browser</div>
                )}
              </div>
              <div className="tx-small">Count</div>
            </div>
            <div className="main">
              {props.name === "countries" &&
                props.data.length > 0 &&
                props.data.map(i => (
                  <div className="my-row" key={i.user_country}>
                    <div
                      className="line"
                      style={{
                        width: `${new BigNumber(i.requests)
                          .div(props.total || "0")
                          .multipliedBy(100)}%`,
                      }}
                    />
                    <div className="tx-medium left">
                      <Image
                        alt="flag"
                        src={`/images/flags/${
                          i.user_country
                            ? i.user_country.toLowerCase()
                            : "unknown"
                        }.svg`}
                        width={20}
                        height={20}
                      />
                      <span className="flag-text">
                        {
                          countriesData.find(
                            item =>
                              item.code.toLowerCase() ===
                              i.user_country.toLowerCase()
                          )?.name
                        }
                      </span>
                    </div>
                    <div className="right tx-medium">{i.requests}</div>
                  </div>
                ))}
              {props.name === "ips" &&
                props.data.map(i => (
                  <div className="my-row" key={i.ip}>
                    <div
                      className="line"
                      style={{
                        width: `${new BigNumber(i.requests)
                          .div(props.total || "0")
                          .multipliedBy(100)}%`,
                      }}
                    />
                    <div className="left tx-medium">{i.ip}</div>
                    <div className="right tx-medium">{i.requests}</div>
                  </div>
                ))}
              {props.name === "browsers" &&
                props.data.map(i => (
                  <div className="my-row" key={i.user_agent_browser}>
                    <div
                      className="line"
                      style={{
                        width: `${new BigNumber(i.requests)
                          .div(props.total || "0")
                          .multipliedBy(100)}%`,
                      }}
                    />
                    <div className="left tx-medium">{i.user_agent_browser}</div>
                    <div className="right tx-medium">{i.requests}</div>
                  </div>
                ))}
              {props.name === "requests" &&
                props.data.map(i => (
                  <div className="my-row" key={i.endpoint}>
                    <div
                      className="line"
                      style={{
                        width: `${new BigNumber(i.requests)
                          .div(props.total || "0")
                          .multipliedBy(100)}%`,
                      }}
                    />
                    <div className="left tx-medium">/{i.endpoint}</div>
                    <div className="right tx-medium">{i.requests}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export { Box };
