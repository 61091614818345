import axios, { AxiosError } from "axios";
import { WorkspaceContext } from "components/providers/WorkspaceProvider";
import { useSession } from "next-auth/react";
import { useContext, useEffect } from "react";
import useSWR, { mutate } from "swr";
import {
  WorkspaceItem,
  WorkspaceByHash,
  WorkspaceFormData,
} from "types/workspaces";

const workspacesKey = "/api/workspaces";

export const useWorkspace = (id: string) => {
  const { data: session } = useSession();

  const key = `${workspacesKey}/${id}`;
  const { data, error } = useSWR(
    session ? key : null,
    () => axios.get<WorkspaceByHash>(key),
    {
      revalidateOnFocus: true,
    }
  );

  return {
    workspace: data?.data,
    isWorkspaceLoading: !error && !data,
    isWorkspaceError: error as Error | AxiosError<WorkspaceByHash>,
  };
};

export const useWorkspaces = () => {
  const { data: session } = useSession();

  const { data, error } = useSWR(
    session ? workspacesKey : null,
    () => axios.get<WorkspaceItem[]>(workspacesKey),
    {
      revalidateOnFocus: false,
    }
  );
  const { workspace: selectedWorkspaceId, setWorkspace: setSelectedWorkspace } =
    useContext(WorkspaceContext);

  useEffect(() => {
    if (!selectedWorkspaceId && data?.data) {
      // Initially "preselect" first workspace
      if (data.data.length > 0) {
        setSelectedWorkspace(data.data[0].id);
      }
    }
  }, [data?.data, selectedWorkspaceId, setSelectedWorkspace]);

  useEffect(() => {
    // Check if workspaceId loaded from context/local storage is available,
    // if not then select first available workspace
    if (selectedWorkspaceId && data?.data) {
      const workspaceFound = data?.data.find(o => o.id === selectedWorkspaceId);
      // Workspace loaded from local storage is not available
      // select first workspace instead
      if (!workspaceFound) {
        setSelectedWorkspace(data.data[0].id);
      }
    }
  }, [data?.data, selectedWorkspaceId, setSelectedWorkspace]);

  const selectedWorkspace = data?.data.find(o => o.id === selectedWorkspaceId);

  return {
    workspaces: data?.data,
    isWorkspacesLoading: !error && !data,
    isWorkspacesError: error as Error | AxiosError<WorkspaceItem>,
    selectedWorkspace,
    setSelectedWorkspace,
  };
};

export const addWorkspace = async (workspaceForm: WorkspaceFormData) => {
  const res = await axios.post<WorkspaceItem>(
    `${workspacesKey}`,
    workspaceForm
  );
  mutate(workspacesKey);
  return res.data;
};

export const updateWorkspace = async (
  workspaceId: string,
  updateObject: WorkspaceFormData
) => {
  const key = `${workspacesKey}/${workspaceId}`;
  const res = await axios.put(key, updateObject);
  mutate(workspacesKey);
  mutate(key);
  return res.data;
};

export const deleteWorkspace = async (workspaceId: string) => {
  await axios.delete(`${workspacesKey}/${workspaceId}`);
  mutate(workspacesKey);
};

export const transferWorkspace = async (
  workspaceId: string,
  newOwnerId: number
) => {
  const key = `${workspacesKey}/${workspaceId}`;

  const res = await axios.post<WorkspaceItem>(`${key}/transfer`, {
    newOwnerId,
  });
  mutate(key);
  return res.data;
};
