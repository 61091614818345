import axios, { AxiosError, AxiosResponse } from "axios";
import { useSession } from "next-auth/react";
import useSWR from "swr";
import { SubscriptionAddonSchema } from "types/addons";

const workspacesKey = "/api/workspaces";

export const useWorkspaceAddons = (id: string) => {
  const { data: session } = useSession();

  const key = `${workspacesKey}/${id}/addons`;
  const { data, error } = useSWR<
    AxiosResponse<SubscriptionAddonSchema[]>,
    AxiosError<SubscriptionAddonSchema[]>
  >(session ? key : null, axios.get);
  return {
    addons: data?.data,
    isAddonsLoading: !error && !data,
    isAddonsError: error,
  };
};
