import { Card } from "components";
import { COLOR, FONT_SIZE } from "constants/styles";
import React from "react";
interface Props {
  title: React.ReactNode;
  description?: React.ReactNode;
  noMargin?: boolean;
  expand?: boolean;
  children?: React.ReactNode;
}

export const ProjectBox = ({
  title,
  description,
  noMargin,
  expand,
  children,
}: Props) => {
  return (
    <Card
      noMargin={noMargin}
      style={{
        marginBottom: noMargin ? undefined : "10px",
        width: expand ? "100%" : undefined,
      }}
    >
      <h6 className="title">{title}</h6>
      {description && <p className="subtitle header-subtitle">{description}</p>}
      {children}

      <style jsx>{`
        .subtitle {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
          margin-bottom: 0.5rem;
        }

        .header-subtitle {
          margin-bottom: 1.5rem;
        }

        .title {
          text-transform: uppercase;
          font-size: ${FONT_SIZE.XS};
          letter-spacing: 0.5px;
          font-weight: 600;
          color: ${COLOR.TEXT_PRIMARY};
        }
      `}</style>
    </Card>
  );
};
