import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { signIn } from "next-auth/react";
import { Controller, useForm } from "react-hook-form";
import { EmailSignFormPayload } from "types/EmailSignIn";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";
import { Button } from "components";
import { COLOR } from "constants/styles";

export default function EmailSignIn({ callbackUrl }: { callbackUrl?: string }) {
  const { register, handleSubmit, formState, control } =
    useForm<EmailSignFormPayload>();
  const errors = formState.errors;
  const showLoading = formState.isSubmitting;
  const onSubmit = async (data: EmailSignFormPayload) => {
    if (!formState.isSubmitting) {
      try {
        await signIn("email", {
          email: data.email,
          callbackUrl,
          captchaToken: data.captchaToken,
        });
      } catch (error) {
        toast.error(
          (error as Error)?.message ??
            "Error while signing in. Please contact our support."
        );
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(data => onSubmit(data))}
      className="wasValidated"
    >
      <div style={{ marginBottom: "10px" }}>
        <div style={{ marginTop: "10px" }}>
          <label htmlFor="email">Email address</label>
          <input
            autoComplete="on"
            className={
              errors?.email
                ? "is-invalid form-control email-input"
                : "form-control email-input"
            }
            {...register("email", {
              required: "Email is empty",
              validate: val => {
                if (!isEmail(val)) {
                  return "Email is not valid";
                }
              },
            })}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
          <div className="recaptcha">
            <Controller
              control={control}
              name="captchaToken"
              rules={{ required: "Please check the checkbox" }}
              render={({ field }) => (
                <ReCAPTCHA
                  onChange={token => {
                    field.onChange(token);
                  }}
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V2 ?? ""}
                />
              )}
            />
            <div className="error">{errors.captchaToken?.message}</div>
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="primary"
        disabled={showLoading}
        loading={showLoading}
        fullWidth
      >
        Sign In
      </Button>

      <style jsx>{`
        .recaptcha {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .error {
          display: flex;
          width: 100%;
          margin-top: 0.25rem;
          font-size: 80%;
          color: ${COLOR.DANGER};
        }
      `}</style>
    </form>
  );
}

export { EmailSignIn };
