import React from "react";
import { IconCopyToClipboard, ProjectBox } from "components";
import Skeleton from "react-loading-skeleton";
import { FONT_SIZE } from "constants/styles";

function ProjectIdBox({ projectId }: { projectId?: string }) {
  const isLoading = !projectId;

  return (
    <ProjectBox title="Project id" expand>
      <div className={`project-id-wrapper ${isLoading ? "blur" : ""}`}>
        <IconCopyToClipboard
          textToCopy={projectId}
          style={{ marginRight: "10px" }}
        />
        <span className="project-id">
          {isLoading ? <Skeleton width={606} height={30} /> : projectId}
        </span>
      </div>
      <style jsx>{`
        .blur {
          filter: blur(5px);
        }

        .project-id-wrapper {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          font-family: "Rubik", sans-serif;
          font-weight: 400;
          font-size: ${FONT_SIZE.XL};
        }
        .project-id {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </ProjectBox>
  );
}

export { ProjectIdBox };
