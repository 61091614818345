import axios, { AxiosError } from "axios";
import useSWR from "swr";
import { POSTOrderContent, PPUBalanceInfo, Quote } from "services/ppu/types";

export const usePPUQuote = (
  orderData: Pick<POSTOrderContent, "items" | "promo_codes">
) => {
  const baseKey = `/api/ppu/orders/quotes`;

  const { data, error } = useSWR([baseKey, JSON.stringify(orderData)], () =>
    axios.post<Quote>(baseKey, orderData)
  );

  return {
    quote: data?.data,
    isQuoteLoading: !error && !data,
    isQuoteError: error as Error | AxiosError<PPUBalanceInfo>,
  };
};
