import axios, { AxiosError, AxiosResponse } from "axios";
import { useSession } from "next-auth/react";
import useSWR from "swr";
import { Billing } from "types/workspaces";

const workspacesKey = "/api/workspaces";

export const useWorkspaceBilling = (id: string) => {
  const { data: session } = useSession();

  const key = `${workspacesKey}/${id}/billing`;
  const { data, error } = useSWR<AxiosResponse<Billing>, AxiosError<Billing>>(
    session ? key : null,
    axios.get
  );
  return {
    billing: data?.data,
    isBillingLoading: !error && !data,
    isBillingError: error,
  };
};
