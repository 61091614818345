import { BORDER_RADIUS, FONT_SIZE } from "constants/styles";
import Link from "next/link";
import React from "react";
import { Button, ButtonProps } from "../Button";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  heading?: React.ReactNode;
  variant: "primary" | "warning" | "info";
  cta?: ButtonProps & {
    href?: string;
  };
  children: React.ReactNode;
}

const getVariantColors = (variant: Props["variant"]) => {
  switch (variant) {
    case "warning":
      return {
        fg: "#856404",
        bg: "#fff3cd",
        border: "#ffeeba",
      };
    case "primary":
    default:
      return {
        fg: "#013682",
        bg: "#cce1fe",
        border: "#b8d5fe",
      };
  }
};

export const Alert = ({ children, cta, heading, variant, ...rest }: Props) => {
  const colors = getVariantColors(variant);
  return (
    <>
      <style jsx>{`
        .b-alert {
          position: relative;
          padding: 12px 16px;
          margin-bottom: 1rem;
          border: 1px solid transparent;
          border-radius: ${BORDER_RADIUS.PRIMARY};

          color: ${colors.fg};
          background-color: ${colors.bg};
          border-color: ${colors.border};
        }

        .heading {
          display: flex;
          margin-bottom: 8px;
          font-size: ${FONT_SIZE.PRIMARY};
          margin-bottom: 0.5rem;
          font-weight: 500;
          text-transform: uppercase;
        }

        .alert-cta {
          display: flex;
          align-items: center;
          margin-left: 12px;
          white-space: nowrap;
        }

        .alert-link {
          cursor: pointer;
          font-weight: 500;
          text-decoration: underline !important;
        }

        .description-wrapper {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }
      `}</style>
      <div className={`b-alert`} {...rest}>
        {heading && <div className="heading">{heading}</div>}
        <div className="description-wrapper">
          <div className="description">{children}</div>
          {cta && (
            <div className="alert-cta">
              {cta.href ? (
                <Link href={cta.href} passHref legacyBehavior>
                  <a>
                    <Button type="submit" size="sm" {...cta} />
                  </a>
                </Link>
              ) : (
                <Button type="submit" size="sm" {...cta} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
