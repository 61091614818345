import { useWindowSize } from "usehooks-ts";
import { useEffect, useState } from "react";

const useMobileLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    const breakpoint = 998;
    if (width < breakpoint) {
      setIsMobileLayout(true);
    } else {
      setIsMobileLayout(false);
      setIsMenuOpen(false);
    }
  }, [width]);

  return {
    isMobileLayout,
    width,
    height,
    setIsMenuOpen,
    isMenuOpen,
  };
};

export default useMobileLayout;
