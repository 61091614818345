import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ERROR,
  JSONPATH_QUERY_EXAMPLE,
  TRIGGER_OPTIONS,
  WEBHOOK_DATA_EXAMPLE,
} from "constants/webhooks";
import { WebhookConditionForm, WebhookFormDataRaw } from "types/webhooks";
import { IoHelpCircleOutline } from "react-icons/io5";
import dynamic from "next/dynamic";
import { parseJsonPath } from "utils/jsonpath";
import Tippy from "@tippyjs/react";
import { toast } from "react-toastify";
import { BORDER_RADIUS, BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";

const AceEditor = dynamic(() => import("./AceEditor"), { ssr: false });

interface Props {
  conditions: WebhookConditionForm[] | undefined;
  index: number;
}

const extractFieldFromJson = (data: string, jsonPath: string): string => {
  try {
    const res = parseJsonPath(JSON.parse(data), jsonPath);
    if (res === null) {
      return "null";
    }
    if (res === undefined) {
      return "";
    }
    return res;
  } catch (error) {
    // most likely invalid JSON in data var
    console.error(error);
    if (error instanceof Error) {
      toast.error(error.message);
    }
    return "";
  }
};

export const JSONPathEditor = ({ conditions, index }: Props) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<WebhookFormDataRaw>();

  const watchConditions = watch("conditions");
  const triggerWatch = watch("trigger", {
    value: "transaction",
    label: "Transaction",
  }) as (typeof TRIGGER_OPTIONS)[number];
  const trigger = triggerWatch.value;

  const [jsonPathEditorInput, setJsonPathEditorInput] = useState(
    JSON.stringify(WEBHOOK_DATA_EXAMPLE[trigger], undefined, 4)
  );

  const isJsonPathCondition =
    watchConditions?.[index]?.type.value === "jsonPath";
  const extractedField = isJsonPathCondition
    ? extractFieldFromJson(
        jsonPathEditorInput,
        watchConditions?.[index].selector ?? ""
      )
    : "";

  return (
    <>
      <style jsx>{`
        .b-row {
          display: flex;
          gap: 28px;
        }

        .b-col {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
        }

        .error {
          display: flex;
          color: ${COLOR.DANGER};
          margin-top: 2px;
        }

        .sub-description {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
          margin-bottom: 1.5rem;
        }

        .tooltip-icon {
          cursor: pointer;
          color: ${COLOR.TEXT_PRIMARY};
          margin-left: 0.2ch;
        }

        .data-sample {
          margin-top: 24px;
          margin-bottom: 8px;
        }

        @media screen and (max-width: ${BREAKPOINT.SM}px) {
          .b-row {
            flex-direction: column;
          }
        }
      `}</style>
      <div className="">
        <h5>JSONPath Query Editor</h5>
        <div className="sub-description">
          JSONPath is a query language for JSON, similar to XPath for XML. You
          can use JSONPath query to specify the JSON field that should be
          matched against the specified value. Use the interactive example below
          to construct your JSONPath query.
        </div>
      </div>
      <div className="b-row">
        <div className="b-col">
          <label htmlFor={`conditions.${index}.selector`}>
            <span>JSONPath Query</span>
            <Tippy
              interactive
              content={
                <>
                  <p>
                    Note that evaluation expressions are disabled for security
                    reasons.&nbsp;
                  </p>
                  <p>
                    If the value extracted is an array then only one of the
                    items needs to match the specified condition&apos;s value in
                    order to satisfy the condition.&nbsp;
                  </p>
                  <span>
                    <span>For more examples of using JSONPath see&nbsp;</span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://github.com/JSONPath-Plus/JSONPath#syntax-through-examples"
                    >
                      JSONPath-Plus documentation
                    </a>
                    <span>.</span>
                  </span>
                </>
              }
            >
              <span className="tooltip-icon">
                <IoHelpCircleOutline size={18} />
              </span>
            </Tippy>
          </label>

          {/* JSONPath Query input */}
          <Controller
            control={control}
            name={`conditions.${index}.selector`}
            rules={{ required: ERROR.FIELD_REQUIRED }}
            defaultValue={
              conditions?.[index]
                ? conditions[index].selector
                : JSONPATH_QUERY_EXAMPLE[trigger]
            }
            render={({ field }) => (
              <input
                placeholder="JSONPath Query"
                autoComplete="off"
                className={
                  errors?.conditions?.[index]
                    ? "is-invalid form-control"
                    : "form-control"
                }
                {...field}
              />
            )}
          />
          {errors?.conditions?.[index]?.selector && (
            <span className="error">
              {errors?.conditions[index]?.selector?.message}
            </span>
          )}
        </div>
        {/* Extracted value read-only input */}
        <div className="b-col">
          <label htmlFor="jsonPathResult">Extracted value</label>

          <input disabled className="form-control" value={extractedField} />
        </div>{" "}
      </div>

      {/* Data sample (code editor) */}
      <div className="data-sample">
        <label htmlFor="jsonPathEditor">Data sample</label>
        <AceEditor
          mode="json"
          theme="solarized_dark"
          defaultValue={JSON.stringify(
            WEBHOOK_DATA_EXAMPLE[trigger],
            undefined,
            4
          )}
          onChange={val => {
            setJsonPathEditorInput(val);
          }}
          name="jsonPathEditor" // css ID
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false }}
          style={{ width: "100%", borderRadius: BORDER_RADIUS.SM }}
        />
      </div>
    </>
  );
};
