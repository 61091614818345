import { BORDER_RADIUS, BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import React from "react";

export interface CardProps
  extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, "title"> {
  hoverable?: boolean;
  noMargin?: boolean;
  title?: React.ReactNode;
  titleCentered?: boolean;
  description?: React.ReactNode;
  children: React.ReactNode;
}

export const Card = ({
  children,
  title,
  description,
  titleCentered,
  hoverable,
  noMargin,
  ...rest
}: CardProps) => {
  return (
    <>
      <style jsx>{`
        .b-card {
          position: relative;
          display: flex;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          background-color: ${COLOR.BG_WHITE};
          background-clip: border-box;
          border: 1px solid rgba(72, 94, 144, 0.16);
          border-radius: ${BORDER_RADIUS.LG};
          border-color: transparent;
          box-shadow: 0 0 25px rgb(26 108 225 / 10%);
          padding: 15px 20px;
        }

        .hoverable {
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }

        .hoverable:hover,
        .hoverable:focus {
          border: 1px solid ${COLOR.BG_PRIMARY};
          background-color: ${COLOR.BG_PRIMARY};
        }

        .b-card + .b-card {
          margin-top: ${noMargin ? "0px" : "8px"};
        }

        .card-heading {
          display: flex;
          flex-direction: column;
          margin-bottom: ${description
            ? "24px"
            : "16px"}; // subtract 8px due to bottom margin on title
        }

        .card-title {
          text-transform: uppercase;
          font-size: ${FONT_SIZE.PRIMARY};
          letter-spacing: 0.5px;
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
          text-align: ${titleCentered ? "center" : "initial"};
        }

        .card-description {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
          /* max-width: max(70%, 300px); */
          max-width: 80%;
        }

        @media (max-width: ${BREAKPOINT.XS}px) {
          .b-card {
            padding: 15px;
          }
        }
      `}</style>
      <div className={`b-card ${hoverable ? "hoverable" : ""}`} {...rest}>
        {(title || description) && (
          <div className="card-heading">
            <h5 className="card-title">{title}</h5>
            {description && (
              <div className="card-description">{description}</div>
            )}
          </div>
        )}
        {children}
      </div>
    </>
  );
};
