import axios from "axios";
import useSWR, { mutate } from "swr";
import { EnhancedSubscription } from "types/Plans";
import { useState } from "react";
import { useSession } from "next-auth/react";

const key = "/api/stripe/get_customer_subscriptions";

export const redirectToStripeCheckout = async (
  priceId: string,
  options?: {
    customerEmail?: string;
    mode?: "subscription" | "payment";
    onBehalfWorkspaceId?: string;
    cancelCallbackPath?: string;
    successCallbackPath?: string;
  }
) => {
  const response = await axios.post<{ url: string }>("/api/checkout", {
    priceId,
    email: options?.customerEmail,
    mode: options?.mode,
    onBehalfWorkspaceId: options?.onBehalfWorkspaceId,
    successCallbackPath: options?.successCallbackPath,
    cancelCallbackPath: options?.cancelCallbackPath,
  });
  return response;
};

export const deleteSubscription = async () => {
  const response = await axios.get("/api/stripe/cancel_subscription");
  if (response.status === 200) {
    mutate(key);
  }
};

export function useSubscriptions() {
  const { data: session } = useSession();
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false);

  const { data: subscriptionsData, error: subscriptionsError } = useSWR(
    session ? key : null,
    async () => {
      return axios.get<EnhancedSubscription>(key);
    }
  );

  const updateSubscription = async (newPriceId: string) => {
    setIsSubscribing(true);
    try {
      const response = await axios.post("/api/stripe/update_subscription", {
        newPriceId,
      });
      if (response.status === 200) {
        mutate(key);
      }
    } catch (error) {
      throw error;
    } finally {
      setIsSubscribing(false);
    }
  };

  const enhancedSubscription = subscriptionsData?.data;
  const hasPaidSubscription =
    enhancedSubscription && enhancedSubscription.currentPlan.name !== "STARTER";

  return {
    enhancedSubscription,
    isSubscribing,
    hasPaidSubscription,
    isSubscriptionsLoading: !subscriptionsError && !subscriptionsData,
    isSubscriptionsError: subscriptionsError,
    updateSubscription,
    deleteSubscription,
    redirectToStripeCheckout,
  };
}
