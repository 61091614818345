import {
  BsDiscord,
  BsFacebook,
  BsGithub,
  BsLinkedin,
  BsTwitter,
} from "react-icons/bs";
import React from "react";
import { COLOR, FONT_SIZE } from "constants/styles";

function Footer() {
  const socialLinks = [
    {
      name: "Twitter",
      href: "https://twitter.com/blockfrost_io",
      icon: BsTwitter,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=100087330920918",
      icon: BsFacebook,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/blockfrost/",
      icon: BsLinkedin,
    },
    {
      name: "GitHub",
      href: "https://github.com/blockfrost",
      icon: BsGithub,
    },
    {
      name: "Discord",
      href: "https://discord.gg/BfFRuU4Yq2",
      icon: BsDiscord,
    },
  ];
  return (
    <footer className="footer">
      <div className="my-row">
        <div className="footer-content">
          <div className="footer-address">
            <strong>Blockfrost.io</strong>
            <span>
              , Five Binaries OÜ, Viru väljak 2, 1011 Tallinn, Estonia
            </span>
          </div>
          <div className="footer-description">
            <span>
              makes it easy to enter the Cardano ecosystem by providing an
              instant API access to the blockchain.&nbsp;
            </span>
            <span>Developers adoption is our primary goal.</span>
          </div>
          <div className="socials">
            {socialLinks.map(({ name, href, icon: Icon }) => (
              <div className="socials-row" key={name}>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  className="social-link"
                >
                  <Icon size={28} />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="footer-copyleft">
          <div>© {new Date().getFullYear()} Five Binaries OÜ</div>
        </div>
      </div>
      <style jsx>{`
        .footer {
          display: flex;
          justify-content: space-between;
          font-size: ${FONT_SIZE.XXS};
          letter-spacing: 0.3px;
          color: ${COLOR.TEXT_LIGHT};
          padding: 16px 24px;
          background-color: ${COLOR.BG_LANDING_DARK};
          text-transform: uppercase;
          letter-spacing: 0.1ch;
          /* box-shadow: 0 0 25px rgb(26 108 225 / 10%); */
        }

        .my-row {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        .footer-content {
          display: flex;
          flex-direction: column;
        }

        .footer-address {
          margin-bottom: 1rem;
        }

        .footer-copyleft {
          display: flex;
          flex-direction: column;
          /* align-self: flex-start; */
          margin-left: 8px;
        }

        .socials {
          display: flex;
          margin-top: 16px;
        }

        .social-link {
          color: ${COLOR.TEXT_LIGHT};
        }
        .socials-row + .socials-row {
          margin-left: 10px;
        }
      `}</style>
    </footer>
  );
}

export { Footer };
