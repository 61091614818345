import React, { useRef, useState } from "react";
import { Check } from "react-feather";
import { BillingCycle, PredefinedPlan } from "types/Plans";
import Tippy from "@tippyjs/react";
import { PricingPlan } from "../PricingPlan";
import { SubscriptionDowngradeConfirmation } from "components/dashboard/SubscriptionDowngradeConfirmation";
import { Button, Spinner } from "components";
import { COLOR } from "constants/styles";

interface Props {
  plan: PredefinedPlan;
  billingCycle: BillingCycle;
  hasPaidSubscription?: boolean;
  canBeUpgraded?: boolean;
  downgradeWarning: string | undefined;
  ppuWarning: string | undefined;
  canBeDowngraded?: boolean;
  selectPlan?: () => Promise<void>;
  isSelected?: boolean;
  isLoading?: boolean;
}

function Plan({
  plan,
  billingCycle,
  selectPlan,
  downgradeWarning,
  ppuWarning,
  hasPaidSubscription,
  isLoading,
  isSelected,
  canBeUpgraded,
  canBeDowngraded,
}: Props) {
  const [isSubscribing, setIsSubscribing] = useState(false);

  const popoverParentRef = useRef<HTMLDivElement | null>(null);

  let ctaBtnLabel: string | undefined;

  if (hasPaidSubscription) {
    if (canBeDowngraded) {
      ctaBtnLabel = "Downgrade plan";
    } else if (canBeUpgraded) {
      ctaBtnLabel = "Upgrade plan";
    }
  } else {
    // customer with STARTER plan
    ctaBtnLabel = "Select plan";
  }

  const ctaBtnPopupContent = downgradeWarning || ppuWarning;
  const ctaBtnDisabled = !!downgradeWarning;

  const onConfirmHandler = async () => {
    setIsSubscribing(true);
    await selectPlan?.();
    setIsSubscribing(false);
  };

  return (
    <>
      <PricingPlan
        predefinedPlan={plan}
        billingCycle={billingCycle}
        style={isSelected ? { background: COLOR.BG_DISABLED } : undefined}
        customButtons={
          <>
            {/* loading subscription */}
            {isLoading && (
              <Spinner size="sm" role="status" aria-hidden="true" />
            )}

            {/* button for non-enterprise plans */}
            {!isLoading && selectPlan && plan.name !== "ENTERPRISE" && (
              <>
                {isSelected && <Check size={40} />}
                {!isSelected && ctaBtnLabel && (
                  <Tippy
                    interactive
                    disabled={!ctaBtnPopupContent}
                    content={ctaBtnPopupContent}
                  >
                    {/* empty div wrapper needed otherwise pointer event is not fired and tooltip doesn't show up*/}
                    <div style={{ width: "100%" }} ref={popoverParentRef}>
                      <SubscriptionDowngradeConfirmation
                        disabled={ctaBtnDisabled || !canBeDowngraded}
                        planName={plan.name}
                        parentElement={
                          popoverParentRef.current
                            ? popoverParentRef.current
                            : undefined
                        }
                        onConfirm={onConfirmHandler}
                      >
                        <Button
                          variant="outline-primary"
                          fullWidth
                          disabled={isSubscribing || ctaBtnDisabled}
                          onClick={async () => {
                            if (!canBeDowngraded) {
                              // with downgrade we show confirm prompt first,
                              // selectPlan() is fired from the prompt itself
                              await onConfirmHandler();
                            }
                          }}
                          data-cy={`upgrade-to-${plan.name.toLowerCase()}`}
                        >
                          {isSubscribing && (
                            <Spinner
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: "8px" }}
                            />
                          )}
                          <span>{ctaBtnLabel}</span>
                        </Button>
                      </SubscriptionDowngradeConfirmation>
                    </div>
                  </Tippy>
                )}
              </>
            )}

            {/* button for enterprise plan */}
            {!isLoading && plan.name === "ENTERPRISE" && (
              <Button
                variant="outline-primary"
                fullWidth
                onClick={() => window.$zopim.livechat.window.show()}
              >
                Contact Us
              </Button>
            )}
          </>
        }
      />
      <style jsx>{``}</style>
    </>
  );
}

export { Plan };
