const NETWORKS = [
  {
    value: 0,
    label: "Cardano mainnet",
    name: "cardano-mainnet",
    projectIdPrefix: "mainnet",
    webhookNetworkId: 0,
  },
  {
    value: 1,
    label: "Cardano testnet",
    name: "cardano-testnet",
    projectIdPrefix: "testnet",
    webhookNetworkId: 1,
  },
  {
    value: 2,
    label: "IPFS",
    name: "ipfs",
    projectIdPrefix: "ipfs",
    webhookNetworkId: undefined,
  },
  {
    value: 3,
    label: "Milkomeda mainnet",
    name: "milkomeda-mainnet",
    projectIdPrefix: "milkmainnet",
    webhookNetworkId: undefined,
  },
  {
    value: 4,
    label: "Milkomeda testnet",
    name: "milkomeda-testnet",
    projectIdPrefix: "milktestnet",
    webhookNetworkId: undefined,
  },
  {
    value: 5,
    label: "Cardano preview",
    name: "cardano-preview",
    projectIdPrefix: "preview",
    webhookNetworkId: 2,
  },
  {
    value: 6,
    label: "Cardano preprod",
    name: "cardano-preprod",
    projectIdPrefix: "preprod",
    webhookNetworkId: 3,
  },
  {
    value: 7,
    label: "Cardano SanchoNet",
    name: "cardano-sanchonet",
    projectIdPrefix: "sanchonet",
    webhookNetworkId: undefined,
  },
] as const;

export enum NetworkEnum {
  CardanoMainnet = 0,
  CardanoTestnet = 1,
  IPFS = 2,
  MilkomedaMainnet = 3,
  MilkomedaTestnet = 4,
  CardanoPreview = 5,
  CardanoPreprod = 6,
  CardanoSanchoNet = 7,
}

export const SORTED_NETWORKS = [...NETWORKS].sort((a, b) =>
  a.label.localeCompare(b.label)
);

export const WEBHOOKS_NETWORKS = SORTED_NETWORKS.filter(
  n => n.webhookNetworkId !== undefined
) as [
  (typeof NETWORKS)[0],
  (typeof NETWORKS)[1],
  (typeof NETWORKS)[5],
  (typeof NETWORKS)[6],
];

export default NETWORKS;
