import React from "react";
import { formatNumber } from "utils/formatting";

interface Props {
  current: number;
  subject: React.ReactNode;
  total?: number;
}

const UsageProgress = (props: Props) => {
  return (
    <>
      <style jsx>{`
        .container {
          display: flex;
        }
        .label {
          display: flex;
          padding-left: 2px;
          padding-top: 4px;
          flex-direction: column;
        }
      `}</style>
      <div className="container">
        {formatNumber(props.current)}
        <span>
          {props.total !== undefined ? `/${formatNumber(props.total)}` : ""}
        </span>
        <small className="label">{props.subject}</small>
      </div>
    </>
  );
};

UsageProgress.displayName = "UsageProgress";
export { UsageProgress };
