import React from "react";
import { BREAKPOINT } from "constants/styles";

export const LightStripes = () => {
  return (
    <>
      {/* <div className="glow"></div> */}
      <div className="glow-end"></div>

      <style jsx>{`
        .glow,
        .glow-end {
          position: absolute;
          opacity: 0.1;
          overflow: hidden;
          z-index: -1;
          background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.8) 0%,
            rgba(255, 255, 255, 1) 70%,
            rgba(255, 255, 255, 0) 100%
          );
        }
        .glow {
          width: 1080px;
          height: 1080px;
          /* border-radius: 50%; */
          transform: rotate(45deg);
          left: -850px;
          top: -650px;
        }

        .glow-end {
          transform: skew(0deg, -45deg);
          width: 150vw;
          height: 400px;
          left: 200px;
          bottom: 100px;
        }

        @media screen and (max-width: ${BREAKPOINT.MD}px) {
          .glow-end {
            left: 0;
          }
        }

        @media screen and (max-width: ${BREAKPOINT.XS}px) {
          .glow {
            width: 300px;
            height: 300px;
            left: -120px;
            top: -150px;
          }
        }
      `}</style>
    </>
  );
};
