import React from "react";
import { IconCopyToClipboard } from "components/layout/IconCopyToClipboard";
import { getNetworkById } from "utils/projects";
import { MilkomedaExamples } from "./MilkomedaExamples";
import { ProjectBox } from "components";

const requestExamples = [
  "api/v0/blocks/latest",
  "api/v0/epochs/latest",
  "api/v0/pools?order=desc",
  "api/v0/metadata/txs/labels",
  "api/v0/pools",
  "api/v0/genesis",
  "api/v0/metrics",
]
  .sort(() => Math.random() - 0.5)
  .slice(0, 3);

const IPFSExamples = [
  "api/v0/ipfs/gateway/QmZbHqiCxKEVX7QfijzJTkZiSi3WEVTcvANgNAWzDYgZDr",
  "api/v0/ipfs/pin/list",
];

interface Props {
  projectId?: string;
  networkId?: number;
}

function Examples({ projectId, networkId }: Props) {
  const isMilkomeda = networkId === 3 || networkId === 4;
  const examples = networkId === 2 ? IPFSExamples : requestExamples;
  const network = getNetworkById(networkId);

  if (isMilkomeda) {
    return <MilkomedaExamples projectId={projectId} networkId={networkId} />;
  }

  return (
    <ProjectBox
      title="API Examples"
      description={
        <>
          <span>
            Start by trying following examples! To see the whole API structure
            check our
          </span>{" "}
          <a href="http://docs.blockfrost.io" rel="noreferrer" target="_blank">
            {" "}
            docs
          </a>
          <span>.</span>
        </>
      }
    >
      {network &&
        examples.map(example => {
          const exampleCommand = `curl -H "project_id: ${network?.projectIdPrefix}${projectId}" \
        https://${network?.name}.blockfrost.io/${example} `;

          return (
            <div key={exampleCommand}>
              <div className="example text-monospace">
                <div className="left">
                  <IconCopyToClipboard textToCopy={exampleCommand} />
                </div>
                <div className="right">{exampleCommand}</div>
                <style jsx>{`
                  .wrapper {
                    display: flex;
                  }

                  .example {
                    display: flex;
                    margin-bottom: 24px;
                  }
                  .left {
                    padding: 0 10px 0 0;
                    text-align: left;
                  }
                  .right {
                    text-align: left;
                  }
                `}</style>
              </div>
            </div>
          );
        })}
    </ProjectBox>
  );
}

export { Examples };
