import React, { useState } from "react";
import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import { PricingPlanHeader } from "components/plans/PricingPlan/PricingPlanHeader";
import { Button } from "components/UI/Button";
import { routes } from "utils/routes";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { IoCheckmarkCircle } from "react-icons/io5";
import { usePPUQuote } from "hooks/usePPUQuote";

const FEATURES = [
  "No monthly commitment",
  "Flexible usage-based pricing",
  "Resources never expire - use them at your pace",
  "Full access to all features",
  "Pay with ₳DA",
];

function PayAsYouGoPricing() {
  const { data: session } = useSession();
  const [isHovering, setIsHovering] = useState(false);
  const { quote } = usePPUQuote({
    items: [{ item_quantity: "1", item_type: "requests" }],
  });

  // TODO: loading, error state using skeleton
  const eurPerRequest =
    quote?.data.pricing.requests.price_per_unit_eur ?? 0.0000024;

  const adaPerRequest = quote
    ? quote.data.pricing.requests.price_per_unit_eur /
      quote.data.pricing.ada_eur_rate
    : 0.0000044839;

  return (
    <>
      <div className="wrapper">
        {/* <h3 className="ppu-title">Don't want to commit? Pay As You Go!</h3> */}
        <div className="pricing-box">
          <PricingPlanHeader planName="Pay As You Go" />

          <div className="price-wrapper">
            <div className="content">
              <div className="subtitle">Starting from</div>
              <div
                className={`price-value`}
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
                onClick={() => {
                  setIsHovering(prev => !prev);
                }}
              >
                {isHovering ? (
                  <>
                    {eurPerRequest.toLocaleString(undefined, {
                      maximumFractionDigits: 7,
                    })}{" "}
                    €
                  </>
                ) : (
                  <>
                    {adaPerRequest.toLocaleString(undefined, {
                      maximumFractionDigits: 7,
                    })}{" "}
                    ₳DA
                  </>
                )}
              </div>

              <div className="desc" style={{ fontWeight: 400 }}>
                <span>per&nbsp;</span>
                <strong>1</strong>
                <span>&nbsp;request</span>
              </div>

              <div
                className="per-100k"
                style={{ fontWeight: 400 }}
                onMouseOver={() => setIsHovering(true)}
                onMouseOut={() => setIsHovering(false)}
                onClick={() => {
                  setIsHovering(prev => !prev);
                }}
              >
                <span className="per-100k-price">
                  <span>≈</span>
                  <span>
                    {isHovering ? (
                      <>
                        {(eurPerRequest * 100000).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}{" "}
                        €
                      </>
                    ) : (
                      <>
                        {(adaPerRequest * 100000).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}{" "}
                        ₳DA
                      </>
                    )}
                  </span>
                </span>
                <span> per&nbsp;</span>
                <strong>100k</strong>
                <span>&nbsp;requests</span>
              </div>
            </div>
          </div>

          <div className="divider"></div>
          <div className="features">
            <div className="content">
              {FEATURES.map(feature => (
                <div className="feature" key={feature}>
                  <IoCheckmarkCircle
                    size={20}
                    style={{ marginRight: 6 }}
                    color={"#7ed2c4"}
                  />
                  <span>{feature}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="buttons">
            <Link
              href={
                session
                  ? routes.dashboardSubscriptionPlans
                  : {
                      pathname: routes.authSignIn,
                      query: {
                        redirect: routes.dashboardSubscriptionPlans,
                      },
                    }
              }
              style={{ width: "100%" }}
            >
              <Button variant="animated-gradient" fullWidth>
                <strong>Start Building</strong>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <style jsx>{`
        .wrapper {
          display: flex;
          justify-content: center;
        }

        .ppu-title {
          text-align: center;
          font-size: ${FONT_SIZE.XXL};
          font-weight: 500;
          padding-bottom: 120px;
        }

        .subtitle {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.LG};
          text-align: center;
          font-weight: 500;
          margin-bottom: 32px;
          /* text-transform: uppercase; */
        }

        .pricing-box {
          border: 1px solid #ececec;
          border-radius: ${BORDER_RADIUS.XL};
          background-color: ${COLOR.BG_WHITE};
          box-shadow: 0 0 20px rgba(84, 35, 199, 0.1);
          overflow: hidden;
        }

        .price-wrapper {
          margin-bottom: 20px;
          margin-top: -12px;
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 32px;
        }
        .price-value {
          font-family: "Rubik", sans-serif;
          color: ${COLOR.PRIMARY};
          font-weight: 400;
          font-size: ${FONT_SIZE.XXL};
          text-align: center;
          line-height: 1;
          margin-bottom: 4px;
          cursor: pointer;
        }

        .desc,
        .per-100k {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.PRIMARY};
          text-align: center;
          font-weight: 500;
          margin-bottom: 24px;
        }

        .per-100k {
          cursor: pointer;
        }

        .per-100k-price {
          /* color: ${COLOR.PRIMARY}; */
          font-size: ${FONT_SIZE.LG};
          font-weight: 500;
        }

        .features {
          display: flex;
          flex-direction: column;
          text-align: left;
          color: ${COLOR.TEXT_SECONDARY};
          font-weight: 500;
          margin: 32px 0;
        }

        .feature {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
        }

        .divider {
          display: flex;
          width: 100%;
          margin: 12px 0;
          height: 1px;
          background-color: ${COLOR.BG_PRIMARY};
        }

        .buttons {
          display: flex;
          justify-content: center;
          margin: 24px;
          height: 38px;
        }
      `}</style>
    </>
  );
}

export { PayAsYouGoPricing };
