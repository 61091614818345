import React from "react";
import { routes } from "utils/routes";
import { useSession } from "next-auth/react";
import Link from "next/link";
import Image from "next/image";
import { IoMenu } from "react-icons/io5";
import { FaHashtag } from "react-icons/fa";
import useMobileLayout from "hooks/useMobileLayout";
import { Container, HoverableIcon } from "components";
import NavbarDropdown from "./NavbarDropdown";
import { BuildAppsNowButton } from "./BuildAppsNowButton";
import { NavbarDropdownSkeleton } from "./NavbarDropdown/NavbarDropdownSkeleton";
import { COLOR, FONT_SIZE, BREAKPOINT } from "constants/styles";

interface Props {
  dashboardMode?: boolean;
}

export const Navbar = ({ dashboardMode }: Props) => {
  const { data: session, status } = useSession();
  const { isMenuOpen, setIsMenuOpen, isMobileLayout } = useMobileLayout();
  const loading = status === "loading";
  const rootHref = session ? routes.dashboard : routes.root;

  return (
    <>
      <style jsx>{`
        .navbar-container-wrapper {
          display: flex;
          position: sticky;
          flex-direction: column;
          top: 0;
          z-index: 1020;
          background: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(10px);
          box-shadow: 0 0 25px rgb(26 108 225 / 10%);
          align-items: center;
          height: 100%;
        }

        .navbar-content {
          display: flex;
          width: 100%;
          padding: 0px 32px;
          align-items: center;
          justify-content: space-between;
        }

        .non-collapsible {
          display: flex;
          height: 56px;
          align-items: center;
        }

        .navbar {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 8px 0px;
        }

        .blockfrost-link {
          font-size: ${FONT_SIZE.PRIMARY};
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
          transition: all 0.2s ease-in-out;
          padding: 8px 16px;
          cursor: pointer;
        }

        .blockfrost-link:hover {
          color: ${COLOR.PRIMARY};
        }

        .documentation-link {
          display: flex;
          align-items: center;
        }

        .full-logo-width {
          display: flex;
          align-items: center;
          min-width: 220px;
        }

        .b-logo-width {
          display: none;
          min-width: 30px;
        }

        .skeleton-wrapper {
          display: flex;
          align-items: center;
        }

        ul.navbar-menu {
          display: flex;
          align-items: center;
          list-style-type: none;
          padding: 0;
          margin: 0;
        }

        .menu-toggle-wrapper {
          display: flex;
          align-items: center;
          /* visible only in mobile layout */
          display: none;
        }

        @media (max-width: ${BREAKPOINT.MD}px) {
          /* mobile layout */

          .b-logo-width {
            display: flex;
          }
          .full-logo-width {
            display: none;
          }

          .navbar-content {
            padding: 0px 16px;
            flex-direction: column;
          }
          .navbar {
            display: ${isMenuOpen ? "flex" : "none"};
            flex-direction: column;
            align-items: flex-start;
          }

          ul.navbar-menu {
            flex-direction: column;
            align-items: flex-start;
          }

          li.b-nav-item {
            padding: 8px 0px;
          }

          .menu-toggle-wrapper {
            display: flex;
          }

          .non-collapsible {
            width: 100%;
            justify-content: space-between;
          }
        }
      `}</style>
      <div className="navbar-container-wrapper">
        {/* Uncomment for sticky announcement */}
        {/* <Announcement
        title="🎉🎉🎉&nbsp;Check out new Blockfrost 3.0&nbsp;🎉🎉🎉"
        href="https://docs.blockfrost.io"
      /> */}
        <Container fluid>
          <div className="navbar-content ">
            <div className="non-collapsible">
              <div className="navbar-brand">
                <div className="full-logo-width">
                  <Link href={rootHref}>
                    <Image
                      width="160"
                      height="30"
                      src="/images/logo.svg"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="b-logo-width">
                  <Link href={rootHref}>
                    <Image
                      width="30"
                      height="30"
                      src="/images/b-logo.svg"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="menu-toggle-wrapper">
                {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
                {!dashboardMode && isMobileLayout && <BuildAppsNowButton />}
                {dashboardMode &&
                  isMobileLayout &&
                  status === "authenticated" && (
                    // Logged user - show  menu dropdown
                    <NavbarDropdown userEmail={session.user?.email ?? "..."} />
                  )}
                <HoverableIcon
                  style={{ marginLeft: "16px" }}
                  icon={<IoMenu size={28} />}
                  onClick={() => {
                    setIsMenuOpen(prevValue => !prevValue);
                  }}
                />
              </div>
            </div>
            <div className="navbar">
              <ul className="navbar-menu">
                <li className="b-nav-item">
                  <Link
                    href="/#introduction"
                    passHref
                    legacyBehavior
                    scroll={false}
                  >
                    <a className="blockfrost-link">Home</a>
                  </Link>
                </li>
                <li className="b-nav-item">
                  <Link href="/#pricing" passHref legacyBehavior scroll={false}>
                    <a className="blockfrost-link">Pricing</a>
                  </Link>
                </li>
                <li className="b-nav-item">
                  <Link
                    href="https://status.blockfrost.io/"
                    passHref
                    legacyBehavior
                  >
                    <a className="blockfrost-link">Status</a>
                  </Link>
                </li>
                <li className="b-nav-item">
                  <Link
                    href="https://blog.blockfrost.io/"
                    passHref
                    legacyBehavior
                  >
                    <a className="blockfrost-link">Blog</a>
                  </Link>
                </li>
                <li className="b-nav-item">
                  <Link
                    href="https://blockfrost.dev/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="blockfrost-link documentation-link">
                      <FaHashtag />
                      <span style={{ marginLeft: "4px" }}>Documentation</span>
                    </div>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-menu">
                {/* Landing page - Always show Dashboard button */}
                {!isMobileLayout && !dashboardMode && <BuildAppsNowButton />}

                {/* Waiting for session to load */}
                {dashboardMode && loading && (
                  <div className="skeleton-wrapper">
                    <NavbarDropdownSkeleton />
                  </div>
                )}
                {/* Logged user - show  menu dropdown */}
                {dashboardMode &&
                  !isMobileLayout &&
                  status === "authenticated" && (
                    <NavbarDropdown userEmail={session.user?.email ?? "..."} />
                  )}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
