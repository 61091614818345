import React, { useState } from "react";
import { bytesToMB } from "utils/projects";
import { formatNumber } from "utils/formatting";
import { PredefinedPlan } from "types/Plans";
import Tippy from "@tippyjs/react";
import { Badge } from "components";
import { COLOR } from "constants/styles";
import Link from "next/link";

interface Props {
  plan: PredefinedPlan;
}

const PlanFeatures = ({ plan }: Props) => {
  const workspaceDescription = `With workspaces you can share your projects with other members or allow them to manage the billing for the workspace.`;
  const [isHovering, setIsHovering] = useState(false);
  return (
    <>
      <style jsx>{`
        .title-plan {
          margin-bottom: 40px;
        }

        .bold {
          font-weight: bold;
          color: ${COLOR.TEXT_PRIMARY};
        }
        .milk-badge {
          margin-left: 5px;
        }
        .text-dashed {
          border-bottom: 1px dashed;
          cursor: pointer;
        }

        .features {
          display: flex;
          flex-direction: column;
          text-align: left;
          min-height: 320px;
          color: ${COLOR.TEXT_SECONDARY};
          margin-bottom: 25px;
        }

        .pb-2 {
          padding-bottom: 0.5rem;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0.3;
          }
          100% {
            opacity: 1;
          }
        }

        .fade-in {
          animation: fadeIn 1s;
        }
      `}</style>
      <div className="features">
        <div className="pb-2">Cardano Mainnet</div>
        <div className="pb-2">Cardano Preview</div>
        <div className="pb-2">Cardano Preprod</div>
        <div className="pb-2">Cardano SanchoNet</div>
        <div className="pb-2">
          <span>Milkomeda Mainnet</span>
          <Badge>BETA</Badge>
        </div>
        <div className="pb-2">
          <span>Milkomeda Testnet</span>
          <Badge>BETA</Badge>
        </div>
        {plan.name === "ENTERPRISE" && (
          <>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;requests a day</span>
            </div>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;IPFS infrastructure</span>
            </div>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;Filecoin infrastructure</span>
            </div>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;projects</span>
            </div>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;webhooks</span>
              <Badge>BETA</Badge>
            </div>
            <div className="pb-2">
              <Tippy
                content={
                  <span className="text-mempool-tooltip">
                    {workspaceDescription}
                  </span>
                }
              >
                <span className="text-dashed">
                  <span className="bold">Custom</span>
                  <span>&nbsp;workspaces</span>
                </span>
              </Tippy>
            </div>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;rate limiting</span>
            </div>
            <div className="pb-2">
              <span className="bold">Custom</span>
              <span>&nbsp;SLA</span>
            </div>
          </>
        )}
        {plan.name !== "ENTERPRISE" && (
          <>
            <div
              className={`pb-2 ${isHovering ? "fade-in" : ""}`}
              onMouseOver={() => setIsHovering(true)}
              onMouseOut={() => setIsHovering(false)}
            >
              {isHovering ? (
                <span className="text-dashed">
                  <span className="bold">
                    ~{formatNumber(plan.requestLimit * 30)}{" "}
                  </span>{" "}
                  <span>requests a month</span>
                </span>
              ) : (
                <span className="text-dashed">
                  <span className="bold">
                    {formatNumber(plan.requestLimit)}{" "}
                  </span>{" "}
                  <span>requests a day</span>
                </span>
              )}
            </div>
            <div className="pb-2">
              <span className="bold">
                {formatNumber(bytesToMB(plan.storageLimit))} MB
              </span>{" "}
              <span>IPFS Storage</span>
            </div>
            <div className="pb-2">
              <span className="bold">
                {formatNumber(bytesToMB(plan.storageLimitFilecoin))} MB
              </span>{" "}
              <span>Filecoin Storage</span>
            </div>
            <div className="pb-2">
              <span className="bold">{plan.projectCount}</span>
              <span>&nbsp;project</span>
              <span>{plan.projectCount === 1 ? "" : "s"}</span>
            </div>
            <div className="pb-2">
              <span className="bold">{plan.webhookCount}</span>
              <span>&nbsp;webhook</span>
              <span>{plan.webhookCount === 1 ? "" : "s"}</span>
              <Badge>BETA</Badge>
            </div>
            <div className="pb-2">
              {plan.name === "DEVELOPER" ? (
                <Tippy
                  content={
                    <span className="text-mempool-tooltip">
                      {workspaceDescription} Plan includes up to{" "}
                      {plan.workspaceMembers} members per workspace.
                    </span>
                  }
                >
                  <span className="text-dashed">
                    <span className="bold">{plan.workspaceCount}</span>
                    <span>&nbsp;workspaces</span>
                    {/* <span>{plan.workspaceCount === 1 ? "" : "s"}</span> */}
                  </span>
                </Tippy>
              ) : (
                <>&nbsp;</>
              )}
            </div>
          </>
        )}

        <div className="pb-2">
          {plan.name === "DEVELOPER" || plan.name === "ENTERPRISE" ? (
            <Tippy
              interactive
              content={
                <span className="text-mempool-tooltip">
                  <p>
                    Access filters serve as your project&apos;s defense
                    mechanism, safeguarding it from any unwanted or suspicious
                    activity.
                  </p>

                  <span>To learn more, see </span>
                  <Link
                    href={
                      "https://blockfrost.dev/docs/overview/settings/access-filters"
                    }
                  >
                    Blockfrost Developer Portal: Access Filters
                  </Link>
                  <span>.</span>
                </span>
              }
            >
              <span className="text-dashed">
                <span>Access filters</span>
              </span>
            </Tippy>
          ) : (
            <>&nbsp;</>
          )}
        </div>
        <div className="pb-2">
          {plan.name === "DEVELOPER" || plan.name === "ENTERPRISE" ? (
            <Tippy
              content={
                <span className="text-mempool-tooltip">
                  Priority mempool cluster with guaranteed delivery and rollback
                  protection
                </span>
              }
            >
              <span className="text-dashed">Premium submit</span>
            </Tippy>
          ) : (
            <>&nbsp;</>
          )}
        </div>
        {plan.name !== "ENTERPRISE" && <div className="pb-2">&nbsp;</div>}
        {plan.name !== "ENTERPRISE" && <div className="pb-2">&nbsp;</div>}
      </div>
    </>
  );
};

export default PlanFeatures;
