import React from "react";
import Skeleton from "react-loading-skeleton";

const DashboardStatsSkeleton = () => {
  return (
    <>
      <Skeleton width={290} height={18} />
      <Skeleton width={260} height={18} style={{ marginTop: "8px" }} />
    </>
  );
};

export { DashboardStatsSkeleton };
