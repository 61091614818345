import React from "react";
import { PaginationItem } from "./PaginationItem";
import { BORDER_RADIUS, FONT_SIZE } from "constants/styles";

interface Props {
  children: React.ReactNode[];
}

export const Pagination = ({ children }: Props) => {
  return (
    <>
      <ul className="b-pagination">{children}</ul>
      <style jsx>{`
        .b-pagination {
          display: flex;
          padding-left: 0;
          list-style: none;
          border-radius: ${BORDER_RADIUS.SM};
          font-size: ${FONT_SIZE.PRIMARY};
          font-weight: 500;
        }
      `}</style>
    </>
  );
};

Pagination.Item = PaginationItem;
