import { JSONPath } from "jsonpath-plus";

export const parseJsonPath = (
  data: unknown,
  query: string
): string | null | undefined => {
  if (!query) {
    return null;
  }
  try {
    const parsedData = JSONPath({
      path: query,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      json: data as any,
      flatten: true,
      preventEval: true,
      wrap: false, // to keep things pretty disable wrapping single value in array
    });

    // note: undefined will be still undefined
    return typeof parsedData !== "string"
      ? JSON.stringify(parsedData)
      : parsedData;
  } catch (error) {
    console.warn(`Failed to parse data with JSONPath query: ${query}`, error);
    return undefined;
  }
};
