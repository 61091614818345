import { Copy, Check } from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useState } from "react";
import Tippy from "@tippyjs/react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  textToCopy?: string;
  size?: number;
}

function IconCopyToClipboard({ textToCopy, size = 15, ...rest }: Props) {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <>
      <Tippy content={copied ? "Copied" : "Copy to clipboard"}>
        <div className="wrapper" {...rest}>
          {copied ? (
            <Check size={size} />
          ) : (
            <CopyToClipboard
              text={textToCopy || ""}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 500);
              }}
            >
              <Copy size={size} role="button" style={{ cursor: "pointer" }} />
            </CopyToClipboard>
          )}
        </div>
      </Tippy>
      <style jsx>{`
        .wrapper {
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
}

export { IconCopyToClipboard };
