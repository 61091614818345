import { BORDER_RADIUS, COLOR } from "constants/styles";
import React from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode;
  isDisabled?: boolean;
}

export const HoverableIcon = ({
  icon,
  onClick,
  isDisabled,
  ...rest
}: Props) => {
  return (
    <>
      <div
        className={`icon-hoverable ${isDisabled ? "icon-disabled" : ""}`}
        onClick={isDisabled ? undefined : onClick}
        {...rest}
      >
        {icon}
      </div>

      <style jsx>{`
        .icon-hoverable {
          display: inline-flex;
          padding: 4px 6px;
          cursor: pointer;
        }

        .icon-hoverable:hover {
          background: ${COLOR.BG_PRIMARY};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          transition: all 0.3s ease-in-out;
        }

        .icon-disabled {
          cursor: auto;
          pointer-events: none;
        }
      `}</style>
    </>
  );
};
