import { BREAKPOINT } from "constants/styles";
import React from "react";
import { IoTrashOutline } from "react-icons/io5";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}
export const RemoveItemBtn = ({ disabled, ...rest }: Props) => {
  return (
    <>
      <style jsx>{`
        .remove-btn-wrapper {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 3px;
          height: 100%;
          pointer-events: ${disabled ? "none" : "auto"};
        }

        .remove-btn-label {
          margin-left: 0.5ch;
        }

        @media screen and (max-width: ${BREAKPOINT.SM}px) {
          .remove-btn-label {
            display: none;
          }
        }
      `}</style>

      <div className="remove-btn-wrapper" {...rest}>
        <IoTrashOutline title={rest.title} size={16} />
        <span className="remove-btn-label">Remove</span>
      </div>
    </>
  );
};
