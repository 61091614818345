import React from "react";
import { IconCopyToClipboard } from "components/layout/IconCopyToClipboard";
import { Alert } from "components";
import { IoHelpCircleOutline } from "react-icons/io5";
import { WebhookDefinition } from "types/webhooks";
import Tippy from "@tippyjs/react";
import Link from "next/link";
import { BORDER_RADIUS, COLOR } from "constants/styles";

interface Props {
  webhook: WebhookDefinition;
}
const WebhookIdentifierToken = ({ webhook }: Props) => {
  return (
    <>
      <style jsx>{`
        .b-row {
          display: flex;
          flex-direction: column;
        }
        .auth-token,
        .uid {
          display: flex;
          align-items: center;
          padding: 7.5px 12px;
          background: ${COLOR.BG_DISABLED};
          color: ${COLOR.TEXT_PRIMARY};
          border-radius: ${BORDER_RADIUS.SM};
          font-style: italic;
          border: 1px solid ${COLOR.BORDER_PRIMARY};
        }
        .auth-token-text {
          margin-left: 1ch;
          color: ${COLOR.TEXT_PRIMARY};
          font-style: italic;
        }

        .tooltip-icon {
          cursor: pointer;
          color: ${COLOR.TEXT_PRIMARY};
          margin-left: 0.3ch;
        }
      `}</style>
      <div className="b-row">
        <label htmlFor="uid">
          <span>Identifier</span>
          <Tippy
            content={
              <span>
                Unique webhook identifier that is included in JSON payload of
                every webhook request.
              </span>
            }
          >
            <span className="tooltip-icon">
              <IoHelpCircleOutline size={18} />
            </span>
          </Tippy>
        </label>
        <div className="uid">
          <span>{webhook.id}</span>
        </div>
      </div>

      <div className="b-row" style={{ marginTop: "24px" }}>
        <label htmlFor="auth-token">Auth token</label>
        <div className="auth-token">
          <IconCopyToClipboard textToCopy={webhook.auth_token} />
          <span className="auth-token-text">{webhook.auth_token}</span>
        </div>
        <Alert
          variant="warning"
          style={{ marginTop: "24px" }}
          heading="About webhook authentication token"
        >
          <p>
            We highly recommend to verify that the events sent to your webhook
            endpoint originated from Blockfrost.
          </p>
          <p>
            <span>
              <span>To learn more, see</span>{" "}
              <Link
                href={
                  "https://blockfrost.dev/docs/start-building/webhooks/webhooks-signatures"
                }
              >
                Blockfrost Developer Portal: Webhooks Signatures
              </Link>
              <span>.</span>
            </span>
          </p>
        </Alert>
      </div>
    </>
  );
};

export { WebhookIdentifierToken };
