import networks from "constants/networks";
import BigNumber from "bignumber.js";
import { formatNumber } from "./formatting";
import { PlanMetadata } from "types/Plans";
import { Quota } from "types/workspaces";
import { COLOR } from "constants/styles";
import { QUOTA_USAGE_WARNING_THRESHOLD } from "constants/settings";

export const getNetworkById = (networkId?: number) => {
  if (typeof networkId === "undefined") {
    return undefined;
  }

  return networks[networkId];
};

const intlFormat = (num: number) => {
  return formatNumber(Math.round(num * 10) / 10);
};

export const abbreviateNumber = (num: number) => {
  if (num >= 1000000) {
    return intlFormat(num / 1000000) + "M";
  }

  if (num >= 1000) {
    return intlFormat(num / 1000) + "k";
  }

  return intlFormat(num);
};

export const getStatusColor = (ipfsUsage: number) => {
  if (ipfsUsage >= 85) {
    return COLOR.DANGER;
  }

  // if (ipfsUsage === 0) {
  return COLOR.TEXT_PRIMARY;
  // }

  // if (ipfsUsage < 25) {
  //   return "#10b759";
  // }

  // if (ipfsUsage >= 25 && ipfsUsage < 50) {
  //   return "#00cccc";
  // }

  // if (ipfsUsage >= 50 && ipfsUsage < 75) {
  //   return "#fd7e14";
  // }
};

export const MBtoBytes = (num: string | number): number => {
  return parseInt(new BigNumber(num).multipliedBy(1000000).toFixed(0), 10);
};
export const bytesToMB = (num: string | number): number => {
  return parseInt(new BigNumber(num).dividedBy(1000000).toFixed(0), 10);
};

export const isUsageCloseToPlanLimits = (
  usage: Omit<Quota["used"]["workspace"], "workspaceMembers">,
  planMetadata: PlanMetadata
) => {
  const projectsTooClose =
    usage.projectCount >=
    planMetadata.projectCount * QUOTA_USAGE_WARNING_THRESHOLD;
  const webhooksTooClose =
    usage.webhookCount >=
    planMetadata.webhookCount * QUOTA_USAGE_WARNING_THRESHOLD;
  const requestsTooClose = new BigNumber(usage.requests).gte(
    new BigNumber(planMetadata.requestLimit).multipliedBy(
      QUOTA_USAGE_WARNING_THRESHOLD
    )
  );
  const storageTooClose = new BigNumber(usage.storage).gte(
    new BigNumber(planMetadata.storageLimit).multipliedBy(
      QUOTA_USAGE_WARNING_THRESHOLD
    )
  );

  return {
    result:
      projectsTooClose ||
      webhooksTooClose ||
      requestsTooClose ||
      storageTooClose,
    projectsTooClose,
    webhooksTooClose,
    requestsTooClose,
    storageTooClose,
  };
};
