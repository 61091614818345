export default [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  {
    name: "Australia",
    code: "AU",
    vat: [
      { value: "au_abn", label: "Australian Business Number (AU ABN)" },
      { value: "au_arn", label: "Australian Taxation Office Reference Number" },
    ],
  },
  {
    name: "Austria",
    code: "AT",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  {
    name: "Belgium",
    code: "BE",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  {
    name: "Brazil",
    code: "BR",
    vat: [
      { value: "br_cnpj", label: "Brazilian CNPJ number" },
      { value: "br_cpf	", label: "Brazilian CPF number" },
    ],
  },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  {
    name: "Bulgaria",
    code: "BG",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  {
    name: "Canada",
    code: "CA",
    vat: [
      { value: "ca_bn", label: "Canadian BN" },
      { value: "ca_gst_hst", label: "Canadian GST/HST number" },
      { value: "ca_pst_bc", label: "Canadian PST number (British Columbia)" },
      { value: "ca_pst_mb", label: "Canadian PST number (Manitoba)" },
      { value: "ca_pst_sk", label: "Canadian PST number (Saskatchewan)" },
      { value: "ca_qst", label: "Canadian QST number (Québec)" },
    ],
  },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  {
    name: "Chile",
    code: "CL",
    vat: [{ value: "cl_tin", label: "Chilean TIN	" }],
  },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  {
    name: "Croatia",
    code: "HR",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Cuba", code: "CU" },
  {
    name: "Cyprus",
    code: "CY",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Czech Republic",
    code: "CZ",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Denmark",
    code: "DK",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  {
    name: "Estonia",
    code: "EE",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  {
    name: "Finland",
    code: "FI",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "France",
    code: "FR",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  {
    name: "Germany",
    code: "DE",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  {
    name: "Greece",
    code: "GR",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  {
    name: "Hong Kong",
    code: "HK",
    vat: [{ value: "hk_br", label: "Hong Kong BR number" }],
  },
  {
    name: "Hungary",
    code: "HU",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Iceland", code: "IS" },
  {
    name: "India",
    code: "IN",
    vat: [{ value: "in_gst", label: "Indian GST number" }],
  },
  {
    name: "Indonesia",
    code: "ID",
    vat: [{ value: "id_npwp", label: "Indonesian NPWP number" }],
  },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  {
    name: "Ireland",
    code: "IE",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Isle of Man", code: "IM" },
  {
    name: "Israel",
    code: "IL",
    vat: [{ value: "il_vat", label: "Israel VAT" }],
  },
  {
    name: "Italy",
    code: "IT",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Jamaica", code: "JM" },
  {
    name: "Japan",
    code: "JP",
    vat: [
      { value: "jp_cn", label: "Japanese Corporate Number (*Hōjin Bangō*)" },
      {
        value: "jp_rn",
        label:
          "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)	",
      },
    ],
  },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  {
    name: "Korea, Republic of",
    code: "KR",
    vat: [{ value: "kr_brn", label: "Korean BRN	" }],
  },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  {
    name: "Latvia",
    code: "LV",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  {
    name: "Liechtenstein",
    code: "LI",
    vat: [{ value: "li_uid", label: "Liechtensteinian UID number" }],
  },
  {
    name: "Lithuania",
    code: "LT",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Luxembourg",
    code: "LU",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  {
    name: "Malaysia",
    code: "MY",
    vat: [
      { value: "my_frp", label: "Malaysian FRP number" },
      { value: "my_itn", label: "Malaysian ITN" },
      { value: "my_sst", label: "Malaysian SST number" },
    ],
  },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  {
    name: "Malta",
    code: "MT",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  {
    name: "Mexico",
    code: "MX",
    vat: [{ value: "mx_rfc", label: "Mexican RFC number" }],
  },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  {
    name: "Netherlands",
    code: "NL",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  {
    name: "New Zealand",
    code: "NZ",
    vat: [{ value: "nz_gst", label: "New Zealand GST number" }],
  },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  {
    name: "Norway",
    code: "NO",
    vat: [{ value: "no_vat", label: "Norwegian VAT number" }],
  },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  {
    name: "Poland",
    code: "PL",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Portugal",
    code: "PT",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  {
    name: "Romania",
    code: "RO",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Russian Federation",
    code: "RU",
    vat: [
      { value: "ru_inn", label: "Russian INN" },
      { value: "ru_kpp", label: "Russian KPP" },
    ],
  },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  {
    name: "Saudi Arabia",
    code: "SA",
    vat: [{ value: "sa_vat", label: "Saudi Arabia VAT	" }],
  },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  {
    name: "Singapore",
    code: "SG",
    vat: [
      { value: "sg_gst", label: "Singaporean GST" },
      { value: "sg_uen", label: "Singaporean UEN" },
    ],
  },
  {
    name: "Slovakia",
    code: "SK",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Slovenia",
    code: "SI",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  {
    name: "South Africa",
    code: "ZA",
    vat: [{ value: "za_vat", label: "South African VAT number" }],
  },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  {
    name: "Spain",
    code: "ES",
    vat: [
      { value: "es_cif", label: "Spanish CIF number" },
      { value: "eu_vat", label: "European VAT number" },
    ],
  },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  {
    name: "Sweden",
    code: "SE",
    vat: [{ value: "eu_vat", label: "European VAT number" }],
  },
  {
    name: "Switzerland",
    code: "CH",
    vat: [{ value: "ch_vat", label: "Switzerland VAT number" }],
  },
  { name: "Syrian Arab Republic", code: "SY" },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    vat: [{ value: "tw_vat", label: "Taiwanese VAT" }],
  },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  {
    name: "Thailand",
    code: "TH",
    vat: [{ value: "th_vat", label: "Taiwanese VAT" }],
  },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  {
    name: "United Arab Emirates",
    code: "AE",
    vat: [{ value: "ae_trn", label: "United Arab Emirates TRN" }],
  },
  {
    name: "United Kingdom",
    code: "GB",
    vat: [
      { value: "gb_vat", label: "United Kingdom VAT number" },
      { value: "eu_vat", label: "Northern Ireland VAT number" },
    ],
  },
  {
    name: "United States",
    code: "US",
    vat: [{ value: "us_ein", label: "United States EIN" }],
  },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
