import axios from "axios";
import { useSession } from "next-auth/react";
import Stripe from "stripe";
import useSWR from "swr";

export const useCustomer = (options: { migrate: boolean }) => {
  const { data: session } = useSession();

  const key = "/api/stripe/get_customer";
  const { data: customerData, error: customerError } = useSWR(
    session ? key : null,
    async () => {
      return axios.get<Stripe.Customer>(key, {
        params: {
          migrate: options.migrate ? options.migrate : undefined,
        },
      });
    }
  );
  const customer = customerData?.data
    ? {
        ...customerData.data,
        delinquent:
          // We show unpaid invoice banner and restrict buying/upgrading sub based on delinquent property.
          // It doesn't make sense to show it to customers that don't have subscription anymore (or is incomplete_expired)
          !!customerData.data.delinquent &&
          (customerData.data.subscriptions?.data.length ?? 0) > 0,
      }
    : undefined;

  return {
    customer,
    customerLoading: !customerError && !customerData,
    customerError,
  };
};
