import React from "react";
import { isUsageCloseToPlanLimits } from "utils/projects";
import { Alert } from "components";
import { useWorkspaceQuota } from "hooks/useWorkspaceQuota";
import { routes, matchRoute } from "utils/routes";
import { ROLE_PERMISSIONS } from "constants/rbac";
import { useRouter } from "next/router";
import { WorkspaceByHash } from "types/workspaces";

interface Props {
  workspace: WorkspaceByHash;
}
export const UsageCloseToLimitsNotification = ({ workspace }: Props) => {
  const { asPath } = useRouter();
  const { quota } = useWorkspaceQuota(workspace.id);

  const workspaceSubUsageCloseToLimits = quota
    ? isUsageCloseToPlanLimits(quota.used.subscription, quota.quota)
    : false;

  const permissionToManageBilling = workspace
    ? !!ROLE_PERMISSIONS.workspace.manage_billing[workspace.role_name]
    : false;

  // Don't show on workspace page, plans
  const isVisibleOnRoute = !matchRoute(asPath, [
    routes.workspace(workspace.id),
    routes.dashboardSubscription,
  ]);

  const storageTooClose =
    workspaceSubUsageCloseToLimits &&
    workspaceSubUsageCloseToLimits.storageTooClose;

  const requestsTooClose =
    workspaceSubUsageCloseToLimits &&
    workspaceSubUsageCloseToLimits.requestsTooClose;

  return (
    <>
      {isVisibleOnRoute && (storageTooClose || requestsTooClose) ? (
        <Alert
          variant="warning"
          cta={
            // If the user is owner of the workspace we can redirect him to the subscription page.
            // Non-owner is redirected to the workspace page where he can buy boost (admin role)
            workspace.is_creator
              ? {
                  children: "Manage subscription",
                  href: routes.dashboardSubscription,
                }
              : {
                  children: "Manage workspace",
                  href: routes.workspace(workspace.id),
                  // variant: "success",
                }
          }
        >
          <span>Subscription for workspace&nbsp;</span>
          <strong>{workspace?.name}</strong>
          <span> is running out of the allocated quota.</span>
          {permissionToManageBilling ? (
            <span>
              {" "}
              {requestsTooClose ? (
                <>
                  To avoid disruptions, consider upgrading your subscription or
                  temporarily boosting the request limit for your projects.
                </>
              ) : (
                <>Please Upgrade the subscription.</>
              )}
            </span>
          ) : (
            <span>
              {" "}
              Contact the owner of the workspace to upgrade the subscription
              plan.
            </span>
          )}
        </Alert>
      ) : undefined}
    </>
  );
};

export default UsageCloseToLimitsNotification;
