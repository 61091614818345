import { BORDER_RADIUS, COLOR } from "constants/styles";
import React from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const RowPlaceholder = ({ children, ...rest }: Props) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: ${COLOR.TEXT_PRIMARY};
          background: ${COLOR.BG_WHITE};
          font-weight: 500;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          min-height: 74px;
          box-shadow: 0 0 25px rgb(26 108 225 / 10%);
        }
      `}</style>
      <div className="wrapper" {...rest}>
        {children}
      </div>
    </>
  );
};
