import React from "react";
import Image from "next/image";
import { RotateCw } from "react-feather";
import { Button } from "../Button";
import { AxiosError } from "axios";

interface Props {
  error: unknown | undefined;
  showRefreshButton?: boolean;
}

export const ErrorComponent = ({ error, showRefreshButton = false }: Props) => {
  let errorMessage;

  if (error instanceof AxiosError) {
    errorMessage = error.response?.data?.error
      ? error.response.data.error
      : error.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <div className="error-wrapper">
      <Image
        src="/images/img20.png"
        width="400"
        height="300"
        alt="Something went wrong"
      />
      <h2 style={{ marginTop: "8px" }}>Oops! Something went wrong.</h2>

      {errorMessage && <p style={{ paddingTop: "0.5rem" }}>{errorMessage}</p>}

      {showRefreshButton && (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            size="sm"
          >
            <RotateCw size={14} style={{ marginRight: "0.5ch" }} />{" "}
            <span>Refresh</span>
          </Button>
        </div>
      )}
      <style jsx>{`
        .error-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};
