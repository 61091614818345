import React, { useEffect, useRef, useState } from "react";
import plans from "constants/plans";
import { Badge, PayAsYouGoPricing, PricingPlan } from "components";
import { SelectionSwitch } from "components/UI/SelectionSwitch";
import { Section } from "components/UI/Section";
import { PlansGrid } from "components/UI/PlansGrid";
import { FONT_SIZE } from "constants/styles";

function Pricing() {
  const pricingRef = useRef<HTMLDivElement | null>(null);

  const [selectedBillingCycle, setBillingCycle] = useState<{
    value: "month" | "year";
    label: React.ReactNode;
  }>({
    value: "month",
    label: "monthly",
  });

  const [selectedPricingModel, setPricingModel] = useState<{
    value: "fixed-plans" | "pay-per-use";
    label: React.ReactNode;
  }>({
    value: "fixed-plans",
    label: "Fixed Plans",
  });

  useEffect(() => {
    // Scroll PAYG pricing into a vew with anchor link
    if (window.location.hash === "#pay-as-you-go") {
      setPricingModel({ value: "pay-per-use", label: "Pay As You Go" });
      if (pricingRef.current) {
        pricingRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <Section
      id="pricing"
      ref={pricingRef}
      title="Our Plans and Pricing"
      background="light"
      description={
        <>
          <span>
            Don&apos;t waste time and costs on a dedicated infrastructure.
          </span>
          <br />
          <span>
            Leave it to us and focus on what is vital for you - building your
            applications.
          </span>
        </>
      }
    >
      <div className="wrapper">
        <div className="plan-type-toggle">
          <SelectionSwitch
            options={[
              {
                value: "fixed-plans",
                label: "Fixed Plans",
                width: "140px",
              },
              {
                value: "pay-per-use",
                label: "Pay As You Go",
                animatedGradient: true,
                width: "140px",
              },
            ]}
            // @ts-expect-error TODO in SelectionSwitch
            onChange={option => setPricingModel(option)}
            value={selectedPricingModel}
          />
          <div className="badge-wrapper">
            <Badge
              style={{
                padding: "6px 8px",
                fontSize: FONT_SIZE.SECONDARY,
                background: "#6c55cc",
                // textTransform: "capitalize",
              }}
            >
              New
            </Badge>
          </div>
        </div>

        {selectedPricingModel.value === "fixed-plans" ? (
          <>
            <div className="billing-cycle-toggle">
              <SelectionSwitch
                options={[
                  {
                    value: "month",
                    label: "Monthly",
                  },
                  {
                    value: "year",
                    label: "Yearly",
                  },
                ]}
                // @ts-expect-error TODO in SelectionSwitch
                onChange={option => setBillingCycle(option)}
                value={selectedBillingCycle}
              />
            </div>

            <PlansGrid>
              {plans.map((plan, i) => (
                <PricingPlan
                  predefinedPlan={plan}
                  billingCycle={selectedBillingCycle.value}
                  key={i}
                />
              ))}
            </PlansGrid>
          </>
        ) : (
          <PayAsYouGoPricing />
        )}
      </div>
      <style jsx>{`
        .wrapper {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        .plan-type-toggle {
          display: flex;
          margin-bottom: 30px;
          justify-content: center;
          position: relative;
        }

        .badge-wrapper {
          position: absolute;
          right: -18px;
          top: -14px;
          transform: rotate(35deg);
        }

        .billing-cycle-toggle {
          display: flex;
          margin-bottom: 30px;
          justify-content: flex-end;
          align-self: flex-end;
        }
      `}</style>
    </Section>
  );
}

export { Pricing };
