import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import React from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const RoleBadge = ({ children, ...rest }: Props) => {
  const isOwner = typeof children === "string" && children === "owner";
  return (
    <>
      <div className="role" {...rest}>
        {children}
      </div>
      <style jsx>{`
        .role {
          text-transform: uppercase;
          display: flex;
          align-items: center;
          font-size: ${FONT_SIZE.XXS};
          padding: 4px 8px;
          background: ${isOwner ? COLOR.BG_DARK : "#6c55cc"};
          color: ${COLOR.TEXT_LIGHT};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          align-self: baseline;
        }
      `}</style>
    </>
  );
};
