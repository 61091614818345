import axios, { AxiosError, AxiosResponse } from "axios";
import { useSession } from "next-auth/react";
import useSWR from "swr";
import { Quota } from "types/workspaces";

const workspacesKey = "/api/workspaces";

export const useWorkspaceQuota = (id: string) => {
  const { data: session } = useSession();

  const key = `${workspacesKey}/${id}/quota`;
  const { data, error } = useSWR<AxiosResponse<Quota>, AxiosError<Quota>>(
    session ? key : null,
    axios.get
  );
  return {
    quota: data?.data,
    isQuotaLoading: !error && !data,
    isQuotaError: error,
  };
};
