export const ROLE_DESCRIPTION = {
  owner: "Owner of the workspace with full permissions.",
  administrator:
    "Full permissions. Allowed to purchase boosts, manage billing, workspace's projects and workspace's members including creating new invites.",
  maintainer:
    "Allowed to create a new project and update or delete existing projects.",
  collaborator: "Read-only access to all workspace projects.",
};

export const ROLE_PERMISSIONS = {
  workspace: {
    change_name: {
      owner: true,
      administrator: true,
      maintainer: false,
      collaborator: false,
    },
    manage_billing: {
      owner: true,
      administrator: true,
      maintainer: false,
      collaborator: false,
    },
    transfer_ownership: {
      owner: true,
      administrator: false,
      maintainer: false,
      collaborator: false,
    },
    delete: {
      owner: true,
      administrator: false,
      maintainer: false,
      collaborator: false,
    },
  },
  projects: {
    add: {
      owner: true,
      administrator: true,
      maintainer: true,
      collaborator: false,
    },
    update: {
      owner: true,
      administrator: true,
      maintainer: true,
      collaborator: false,
    },
    delete: {
      owner: true,
      administrator: true,
      maintainer: true,
      collaborator: false,
    },
  },
  members: {
    update: {
      // updating roles
      owner: true,
      administrator: true,
      maintainer: false,
      collaborator: false,
    },
    delete: {
      owner: true,
      administrator: true,
      maintainer: false,
      collaborator: false,
    },
  },
  invites: {
    add: {
      owner: true,
      administrator: true,
      maintainer: false,
      collaborator: false,
    },
    delete: {
      owner: true,
      administrator: true,
      maintainer: false,
      collaborator: false,
    },
  },
};

export const INVITES_LIMIT = 10;
