import React from "react";
import Link from "next/link";
import { BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import { useSession } from "next-auth/react";
import HeadingAnimation from "./HeadingAnimation";
import { Button } from "components/UI/Button";
import { IoCubeOutline } from "react-icons/io5";
import { Container } from "components/UI/Container";
import { routes } from "utils/routes";
import { HiOutlineHashtag } from "react-icons/hi2";
import { FloatingBlocks } from "./FloatingBlocks";
import { Terminal } from "./Terminal";
import { LightStripes } from "./LightStripes";

function Introduction() {
  const { data: session } = useSession();

  return (
    <>
      <div className="stripe-intro" id="introduction">
        <FloatingBlocks />
        <LightStripes />

        <Container content noBackground>
          <HeadingAnimation />

          {/* <div className="intro-subtitle">
            <span>
              Query decentralized data faster with our instant and scalable API.
            </span>
            <span>
              Join the vast network of developers building on Cardano.
            </span>
          </div> */}

          <div className="showcase">
            <div className="actions">
              <div className="feature">
                <div className="feature-label">Cardano API</div>
                <div className="feature-description">
                  Tap into Cardano&apos;s potential with our comprehensive set
                  of API endpoints. Create dApps, transact and explore the
                  blockchain.
                </div>
              </div>

              <div className="feature">
                <div className="feature-label">Webhooks</div>
                <div className="feature-description">
                  Receive real-time notifications for events crucial to your
                  application without the need for manual checks.
                  {/* Webhooks provide real-time notifications for events crucial to
                  your application. This ensures prompt updates without the need
                  for manual checks, streamlining your processes and reducing
                  effort. */}
                </div>
              </div>

              <div className="feature">
                <div className="feature-label">IPFS</div>
                <div className="feature-description">
                  Decentralized file storage solution, ideal for the Web3
                  ecosystem.
                </div>
              </div>

              <div className="buttons">
                <div className="button-wrapper">
                  <Link href="https://blockfrost.dev">
                    <Button
                      variant="primary"
                      size="normal"
                      // uppercase
                      icon={<HiOutlineHashtag size={24} />}
                      style={{
                        fontWeight: 600,
                        padding: "12px 24px",
                        borderRadius: "25px",
                      }}
                    >
                      READ DOCS
                    </Button>
                  </Link>
                </div>
                <div className="button-wrapper">
                  <Link href={session ? routes.dashboard : routes.authSignIn}>
                    <Button
                      variant="animated-gradient"
                      size="normal"
                      // uppercase
                      icon={<IoCubeOutline size={24} />}
                      style={{
                        fontWeight: 600,
                        padding: "12px 24px",
                        borderRadius: "25px",
                      }}
                    >
                      START BUILDING
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <Terminal />
          </div>
        </Container>

        <Container content noBackground>
          <div className="highlights">
            <div className="highlight">
              <div className="highlight-value">100M+</div>
              <div className="highlight-label">Requests per day</div>
            </div>
            <div className="highlight">
              <div className="highlight-value">20K+</div>
              <div className="highlight-label">Developers</div>
            </div>
            <div className="highlight">
              <div className="highlight-value">99.9%</div>
              <div className="highlight-label">Reliable Uptime</div>
            </div>
          </div>
        </Container>
      </div>

      <style jsx>{`
        .intro-subtitle {
          display: flex;
          flex-direction: column;
          margin-top: 64px;
          font-weight: 500;
          color: ${COLOR.TEXT_LIGHT};
          font-size: ${FONT_SIZE.XL};
        }

        .stripe-intro {
          display: flex;
          z-index: 3;
          flex-direction: column;
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 100px), 0% 100%);
          background-image: linear-gradient(135deg, #4a15e9, #8d1fbc);
        }

        .stripe-intro {
          position: relative;
          overflow: hidden;
        }

        .highlights {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          margin-top: 32px;
          margin-bottom: 128px;
        }

        .highlight {
          display: flex;
          flex-direction: column;
        }

        .highlight + .highlight {
          margin-left: 128px;
        }

        .showcase {
          display: flex;
          margin-top: 96px;
          align-items: flex-start;
          margin-bottom: 32px;
        }

        .actions {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
        }

        .feature + .feature {
          margin-top: 42px;
        }

        .feature-label {
          text-transform: uppercase;
          letter-spacing: 0.2ch;
          font-size: ${FONT_SIZE.XL};
          font-weight: 600;
          color: ${COLOR.TEXT_WHITE};
        }
        .feature-description {
          font-size: ${FONT_SIZE.LG};
          font-weight: 500;
          color: ${COLOR.TEXT_LIGHT};
        }

        .feature-label + .feature-label {
          margin-top: 32px;
        }

        .buttons {
          display: flex;
          justify-content: center;
          margin-top: 32px;
        }
        .button-wrapper + .button-wrapper {
          margin-left: 32px;
        }

        .highlight-value {
          font-size: 24px;
          font-weight: 500;
          color: ${COLOR.TEXT_LIGHT};
        }
        .highlight-label {
          font-size: ${FONT_SIZE.LG};
          font-weight: 500;
          color: ${COLOR.TEXT_LIGHT};
        }

        @media screen and (max-width: ${BREAKPOINT.MD}px) {
          .showcase {
            flex-direction: column;
          }

          .buttons {
            margin-top: 96px;
          }
        }

        @media screen and (max-width: ${BREAKPOINT.XS}px) {
          .showcase {
            margin-top: 48px;
          }

          .highlight + .highlight {
            margin-left: 32px;
          }

          .buttons {
            flex-direction: column;
            align-items: center;
          }

          .button-wrapper {
            display: flex;
            width: 100%;
          }
          .button-wrapper :global(a) {
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          .button-wrapper + .button-wrapper {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      `}</style>
    </>
  );
}

export { Introduction };
