import { IconCopyToClipboard, ProjectBox } from "components";
import React from "react";
import { getNetworkById } from "utils/projects";
import { MetamaskButton } from "../MetamaskButton";
import { COLOR, FONT_SIZE } from "constants/styles";

interface Props {
  projectId?: string;
  networkId?: number;
}

function MilkomedaExamples({ projectId, networkId }: Props) {
  const network = getNetworkById(networkId);
  const web3Example = `https://${network?.projectIdPrefix}:${network?.projectIdPrefix}${projectId}@${network?.name}.blockfrost.io/api/v0/`;
  const examples = [
    `curl -X POST -H 'project_id: ${network?.projectIdPrefix}${projectId}' -H 'Content-Type: application/json' -d '{"jsonrpc": "2.0", "id": 1, "method": "eth_blockNumber", "params": []}' https://${network?.name}.blockfrost.io/api/v0/`,
    `curl -X POST -H 'project_id: ${network?.projectIdPrefix}${projectId}' -H 'Content-Type: application/json' -d '{"jsonrpc": "2.0", "id": 1, "method": "eth_getBalance", "params": [ "0x4944C27C26658dB002e9dad36D5C385135f3253A","latest" ]}' https://${network?.name}.blockfrost.io/api/v0/`,
  ];

  return (
    <ProjectBox
      title="API Examples"
      description={
        <>
          <span>
            Start by trying following examples! To see the whole API structure
            check our
          </span>{" "}
          <a href="http://docs.blockfrost.io" rel="noreferrer" target="_blank">
            {" "}
            docs
          </a>
          <span>.</span>
        </>
      }
    >
      {examples.map(example => (
        <div key={example} className="snippet">
          <div className="icon-wrapper">
            <IconCopyToClipboard textToCopy={example} />
          </div>
          {example}
        </div>
      ))}

      <p className="subtitle">Web3 example:</p>
      <div className="snippet">
        <div className="icon-wrapper">
          <IconCopyToClipboard textToCopy={web3Example} />
        </div>
        {web3Example}
      </div>
      <div className="metamask-btn-wrapper">
        <MetamaskButton rpcUrl={web3Example} networkId={networkId} />
      </div>

      <style jsx>{`
        .icon-wrapper {
          padding-right: 10px;
        }

        .subtitle {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
          margin-bottom: 0.5rem;
        }

        .snippet {
          display: flex;
          margin-bottom: 1.5rem;
          font-size: ${FONT_SIZE.PRIMARY};
          font-family: SFMono-Regular, Menlo, Monaco, Consolas,
            "Liberation Mono", "Courier New", monospace;
        }

        .metamask-btn-wrapper {
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </ProjectBox>
  );
}

export { MilkomedaExamples };
