import { COLOR, FONT_SIZE } from "constants/styles";
import React from "react";

type Props = React.HtmlHTMLAttributes<HTMLDivElement>;

export const TableColHeading = ({ children, ...rest }: Props) => {
  return (
    <>
      <div className="table-heading" {...rest}>
        {children}
      </div>

      <style jsx>{`
        .table-heading {
          text-transform: uppercase;
          font-size: ${FONT_SIZE.SECONDARY};
          letter-spacing: 0.5px;
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};
