import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import { routes } from "utils/routes";
import { useEffect, useState } from "react";

export const useDashboard = () => {
  const router = useRouter();
  const [redirected, setRedirected] = useState(false);
  const { data: session, status } = useSession();

  useEffect(() => {
    if (redirected) return;

    if (status === "unauthenticated") {
      setRedirected(true);
      router.push({
        pathname: routes.authSignIn,
        query: { redirect: router.asPath },
      });
    }
  }, [router, status, redirected]);

  if (session) {
    Sentry.setUser({ email: session.user?.email || "" });
  }

  return { session, status };
};
