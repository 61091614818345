import React from "react";
import Skeleton from "react-loading-skeleton";
import { ProjectBox } from "components";
import { FONT_SIZE } from "constants/styles";

function SmallBox({
  data,
}: {
  data: {
    title: string;
    value: string | number | undefined;
  };
}) {
  const isLoading = typeof data.value === "undefined";

  return (
    <ProjectBox title={data.title} noMargin expand>
      <div className={`value ${isLoading ? "blur" : ""}`}>
        {isLoading ? <Skeleton width={26} height={26} /> : data.value}
      </div>

      <style jsx>{`
        .value {
          font-family: "Rubik", sans-serif;
          font-weight: 400;
          margin-right: 5px;
          font-size: ${FONT_SIZE.XL};
        }
        .blur {
          filter: blur(5px);
        }
      `}</style>
    </ProjectBox>
  );
}

export { SmallBox };
