import React from "react";
import { useForm } from "react-hook-form";
import { Button, Card } from "components";
import { isAddressValid } from "utils/cardano";
import isURL from "validator/lib/isURL";
import { SubmitPlaceholderProps, AddSubmitInput } from "types/Submits";
import { BREAKPOINT } from "constants/styles";

function SubmitAddPlaceholder({ onCancel, onSave }: SubmitPlaceholderProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddSubmitInput>();

  return (
    <Card>
      <style jsx>{`
        .form-content {
          display: flex;
          gap: 10px;
        }

        .b-col {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
        }

        .actions {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          white-space: nowrap;
          flex: 1 1 auto;
          padding-top: 12px;
        }

        @media screen and (max-width: ${BREAKPOINT.MD}px) {
          .form-content {
            flex-direction: column;
          }
        }
      `}</style>
      <form
        onSubmit={handleSubmit(async data => onSave(data))}
        className="wasValidated"
      >
        <div className="form-content">
          <div className="b-col">
            <label htmlFor="endpoint">Submit API Endpoint</label>
            <input
              autoComplete="on"
              className={
                errors?.endpoint ? "is-invalid form-control" : "form-control"
              }
              {...register("endpoint", {
                required: "Endpoint is empty",
                validate: (value: string) => {
                  const isValid = isURL(value);

                  if (!isValid) {
                    return "URL is not valid";
                  }
                },
              })}
            />
            <div className="invalid-feedback">{errors?.endpoint?.message}</div>
          </div>
          <div className="b-col">
            <label htmlFor="paymentAddress">Payment address</label>
            <input
              autoComplete="on"
              className={
                errors?.paymentAddress
                  ? "is-invalid form-control"
                  : "form-control"
              }
              {...register("paymentAddress", {
                required: "Address is empty",
                validate: value => {
                  const isValid = isAddressValid(value as unknown as string);

                  if (!isValid) {
                    return "Address format is not valid";
                  }
                },
              })}
            />
            <div className="invalid-feedback">
              {errors?.paymentAddress?.message}
            </div>
          </div>
          <div className="actions ">
            <Button uppercase variant="secondary-alt" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="success"
              uppercase
              style={{ marginLeft: "5px" }}
            >
              Save Endpoint
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
}

export { SubmitAddPlaceholder };
