import axios from "axios";
import { useSession } from "next-auth/react";
import useSWR from "swr";
import { HistoryData } from "types/api/history";
import { useStickyResult } from "./useStickyResult";

export function useGraphData(projectUUID: string) {
  const { data: session } = useSession();
  const key = `/api/projects/${projectUUID}/history`;

  const swr = useSWR<HistoryData>(
    session ? key : null,
    () => axios.get(key).then(res => res.data),
    {
      revalidateOnFocus: false,
      refreshInterval: 15000,
    }
  );

  // keeps last result even if new fetch fails so user never sees an error
  const stickyData = useStickyResult(swr.data);

  const { error } = swr;
  return {
    graphData: stickyData,
    isGraphDataLoading: !error && !stickyData,
    isGraphDataError: !stickyData && error,
  };
}
