import React from "react";
import { signIn } from "next-auth/react";
import { Button } from "components";

export default function GithubSignIn({
  callbackUrl,
}: {
  callbackUrl?: string;
}) {
  return (
    <Button
      variant="outline-primary"
      fullWidth
      onClick={() => signIn("github", { callbackUrl })}
    >
      Sign In with GitHub
    </Button>
  );
}

export { GithubSignIn };
