import React, { useEffect, useState } from "react";
import { hasCookie, setCookie } from "cookies-next";
import { Button } from "components";
import { COLOR, BORDER_RADIUS } from "constants/styles";

export const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    setShowConsent(hasCookie("localConsent"));
  }, []);

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie("localConsent", "true", {});
  };

  if (showConsent) {
    return null;
  }

  return (
    <div className="wrapper">
      <style jsx>{`
        .wrapper {
          display: flex;
          margin: 0 20px;
          justify-content: center;
          color: ${COLOR.PRIMARY};
          position: fixed;
          display: flex;
          z-index: 9999;
          bottom: 20px;
          left: 0;
          right: 0;
        }
        .content {
          display: flex;
          align-items: center;
          border: 1px solid rgba(72, 94, 144, 0.16);
          border-radius: ${BORDER_RADIUS.LG};
          border-color: transparent;
          box-shadow: 0 0 25px rgb(26 108 225 / 10%);
          background: white;
          padding: 5px 10px;
          border-radius: 10px;
        }
        .text {
          color: ${COLOR.SECONDARY};
          padding-right: 20px;
        }
      `}</style>
      <div className="content">
        <span className="text">
          This website uses cookies to improve user experience. By using our
          website you consent to all cookies in accordance with our Cookie
          Policy.
        </span>
        <Button
          data-cy="cookie-consent-accept"
          size="sm"
          onClick={() => acceptCookie()}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};
