import { isProduction } from "utils/environment";

export const OLD_PRICES_TO_PRODUCT_MAP: Record<
  string,
  { product: string; price: string } | undefined
> = isProduction()
  ? {
      // Mapping from old prices to new products (as part of migration to Stripe Checkout)
      price_1LYUY6ASZltP0rL6aHmEgcpD: {
        // 10M requests, 100MB IPFS, 10 projects, 100/5000 RL
        product: "prod_Ml2g9RFJLN1y3f",
        price: "price_1M1WbSASZltP0rL6Q4PVkcWg",
      },
      price_1LXSWdASZltP0rL64ZFGJUZJ: {
        // Standard Enterprise plan
        // 5M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2o1GSuw4TPpi",
        price: "price_1M1WiOASZltP0rL63Xua9xvO",
      },
      price_1LQrl8ASZltP0rL6B057jat9: {
        // 60M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2g0UPlTWEc9l",
        price: "price_1M1WbSASZltP0rL6HB06Udxa",
      },
      price_1LLTzcASZltP0rL6lD9CShZp: {
        // 1M requests, 1TB IPFS, 10 projects
        product: "prod_Ml2g1SQmWcFbiQ",
        price: "price_1M1WbTASZltP0rL6jsUJiDMF",
      },
      price_1L6Cv6ASZltP0rL6YNFQ4uRo: {
        // 1M requests, 150GB IPFS, 10 projects
        product: "prod_Ml2g43nIrCgGRF",
        price: "price_1M1WbUASZltP0rL68cc4XhQA",
      },
      price_1L2fZyASZltP0rL6VBn3SNDW: {
        // 50M requests, 100GB IPFS, 10 projects
        product: "prod_Ml2gOixjRshON4",
        price: "price_1M1WbUASZltP0rL6CR5FJ2zR",
      },
      price_1L0ECuASZltP0rL6jZLGLFD7: {
        // 100M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2gTLyuLbmn4E",
        price: "price_1M1WbVASZltP0rL6pcF6CGQr",
      },
      price_1L01DQASZltP0rL6xYfzs0l3: {
        // Archived, no active subs
        product: "prod_Ml2gFC0DG8Jfrp",
        price: "price_1M1WbVASZltP0rL64YWA9Uq0",
      },
      price_1KlyFlASZltP0rL61TIsJZsC: {
        // 200M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2gbsg9tsOoyg",
        price: "price_1M1WbWASZltP0rL6qsFSdjAu",
      },
      price_1KlDVRASZltP0rL6gW9mEQw3: {
        // 300M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2grYsAOPBRrP",
        price: "price_1M1WbWASZltP0rL6u0Bq3qEa",
      },
      price_1KO9ZJASZltP0rL6eAOzoyOW: {
        // 10M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2glyZn5dYNNF",
        price: "price_1M1WbXASZltP0rL6iF6HpcJ7",
      },
      price_1KO6xhASZltP0rL6mBc6XFPV: {
        // Archived, no active subs
        product: "prod_Ml2gt9tyv8F7CK",
        price: "price_1M1WbYASZltP0rL6MzHxaROk",
      },
      price_1KKrtLASZltP0rL6l79CdF6k: {
        // 15M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2gpM8yAYNF5a",
        price: "price_1M1WbYASZltP0rL6i7uiBk7B",
      },
      price_1K5bvHASZltP0rL66GByZxX9: {
        // 5M requests, 250GB IPFS, 10 projects
        product: "prod_Ml2gJXCq8hrGrl",
        price: "price_1M1WbZASZltP0rL68iPHeRZh",
      },
      price_1JkV1CASZltP0rL6HkudFOhA: {
        // Developer
        product: "prod_Ml2gBNIpkxWLVs",
        price: "price_1M7gg5ASZltP0rL6FcOwPtjg",
      },
      price_1JkUsBASZltP0rL6aK8xfKVh: {
        // Hobby
        product: "prod_Ml2h8cAtJGKMMj",
        price: "price_1M7ge8ASZltP0rL6R6BlPf3T",
      },
      price_1JaisxASZltP0rL61DnGYmnP: {
        // 10M requests, 100MB IPFS, 10 projects
        product: "prod_Ml2hBYnyuc5uXi",
        price: "price_1M1WbcASZltP0rL6FjsZnAqR",
      },
      price_1IEHbyASZltP0rL65gqUbLBs: {
        product: "prod_Ml2gBNIpkxWLVs",
        price: "price_1M7gg5ASZltP0rL6FcOwPtjg",
      }, // developer legacy
      price_1IEHbeASZltP0rL6WzZOolwE: {
        product: "prod_Ml2h8cAtJGKMMj",
        price: "price_1M7ge8ASZltP0rL6R6BlPf3T",
      }, // hobby legacy
    }
  : {
      price_1M1UscASZltP0rL629c8SYlv: {
        // Enterprise Plan #383
        product: "prod_MlOtG5rIXyNQqt",
        price: "price_1M1s5fASZltP0rL6pGbbM0rD",
      },
      price_1JkTFBASZltP0rL6ZBTPkItG: {
        // hobby
        product: "prod_MlOt3UsZQnkbQL",
        price: "price_1M7yoGASZltP0rL6762rpkGs",
      },
      price_1JkRjeASZltP0rL6pACgEte6: {
        // developer
        product: "prod_MlOtqzc08W4Zbu",
        price: "price_1M7ymtASZltP0rL6RU7FnuS9",
      },
      price_1JiMLHASZltP0rL6wZykjGuG: {
        // enterprise
        product: "prod_MlOtntSjNzjReo",
        price: "price_1M1s5iASZltP0rL6Y0VaTAO4",
      },
      price_1JaeHEASZltP0rL6LvzOVLIC: {
        // Enterprise Plan #387
        product: "prod_MlOtusUOp6ciGJ",
        price: "price_1M1s5jASZltP0rL6tx1e2ZXo",
      },
      price_1IB0X8ASZltP0rL6I41Svxlj: {
        // developer
        product: "prod_MlOtqzc08W4Zbu",
        price: "price_1M7ymtASZltP0rL6RU7FnuS9",
      },
    };

// Note: Project limits hardcoded within each plan are used only for rendering the information on Pricing page
// Limits that are synced to anal DB are fetched directly from Stripe a (they are attached in product's metadata).

export const STARTER_PLAN = {
  name: "STARTER",
  stripeProductId: undefined,
  stripePriceId: undefined,
  image: "/images/plan_starter.svg",
  requestLimit: 50000,
  projectCount: 1,
  webhookCount: 1,
  workspaceCount: 1,
  workspaceMembers: 1, // only the owner
  storageLimit: "100000000",
  storageLimitFilecoin: "10000000",
  limitPerSecond: 10,
  limitBurst: 500,
} as const;

export const HOBBY_PLAN = {
  name: "HOBBY",
  stripeProductId: isProduction()
    ? "prod_Ml2h8cAtJGKMMj"
    : "prod_MlOt3UsZQnkbQL",
  stripePriceId: isProduction()
    ? {
        // production
        month: {
          id: "price_1M7ge8ASZltP0rL6R6BlPf3T",
          price: 29,
          price_currency: "eur",
        },
        year: {
          id: "price_1MBhCyASZltP0rL6rikfU5sF",
          price: 299,
          price_currency: "eur",
        },
      }
    : {
        // development
        month: {
          id: "price_1M7yoGASZltP0rL6762rpkGs",
          price: 29,
          price_currency: "eur",
        },
        year: {
          id: "price_1M9SNAASZltP0rL6amKW6UEt",
          price: 299,
          price_currency: "eur",
        },
      },
  image: "/images/plan_hobby.svg",
  requestLimit: 300000,
  projectCount: 3,
  webhookCount: 3,
  workspaceCount: 1,
  workspaceMembers: 1,
  storageLimit: "1000000000",
  storageLimitFilecoin: "100000000",
  limitPerSecond: 10,
  limitBurst: 500,
} as const;

export const DEVELOPER_PLAN = {
  name: "DEVELOPER",
  stripeProductId: isProduction()
    ? "prod_Ml2gBNIpkxWLVs"
    : "prod_MlOtqzc08W4Zbu",
  stripePriceId: isProduction()
    ? {
        // production
        month: {
          id: "price_1M7gg5ASZltP0rL6FcOwPtjg",
          price: 79,
          price_currency: "eur",
        },
        year: {
          id: "price_1McABaASZltP0rL6IeGLLnB3",
          price: 799,
          price_currency: "eur",
        },
      }
    : {
        // development
        month: {
          id: "price_1M7ymtASZltP0rL6RU7FnuS9",
          price: 79,
          price_currency: "eur",
        },
        year: {
          id: "price_1McAJiASZltP0rL6VDo49kEp",
          price: 799,
          price_currency: "eur",
        },
      },
  image: "/images/plan_developer.svg",
  requestLimit: 1000000,
  projectCount: 10,
  webhookCount: 10,
  workspaceCount: 2,
  workspaceMembers: 5,
  storageLimit: "50000000000",
  storageLimitFilecoin: "1000000000",
  limitPerSecond: 10,
  limitBurst: 500,
} as const;

// Data for generic enterprise plan, each customer has plan with unique id
export const ENTERPRISE_PLAN = {
  name: "ENTERPRISE",
  stripeProductId: isProduction()
    ? "prod_Ml2o1GSuw4TPpi"
    : "prod_MlOtntSjNzjReo",
  stripePriceId: isProduction()
    ? {
        month: {
          id: "price_1M1WiOASZltP0rL63Xua9xvO",
          price: 0,
          price_currency: "eur",
        },
        year: { id: "", price: 0, price_currency: "eur" },
      }
    : {
        month: {
          id: "price_1M1s5iASZltP0rL6Y0VaTAO4",
          price: 0,
          price_currency: "eur",
        },
        year: { id: "", price: 0, price_currency: "eur" },
      },
  image: "/images/plan_enterprise.svg",
  requestLimit: 10000000,
  projectCount: 10,
  webhookCount: 10,
  workspaceCount: 3,
  workspaceMembers: 10,
  storageLimit: "100000000",
  storageLimitFilecoin: "1000000000",
  limitPerSecond: 10,
  limitBurst: 500,
} as const;

// Static plans used on pricing page
const plans = [STARTER_PLAN, HOBBY_PLAN, DEVELOPER_PLAN, ENTERPRISE_PLAN];

export const DEFAULT_PPU_QUOTA = {
  workspaceMembers: 100,
  workspaceCount: 20,
};

export default plans;
