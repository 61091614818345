import { Card, Button } from "components";
import { FONT_SIZE, COLOR } from "constants/styles";
import React, { useState } from "react";
import { X, Trash2 } from "react-feather";
import { Popover } from "react-tiny-popover";

function ButtonDeleteWithConfirm({
  deleteFunction,
  description,
}: {
  deleteFunction: () => Promise<void>;
  description: string;
}) {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom"]}
      align="end"
      padding={10}
      content={
        <Card>
          <style jsx>{`
            .right {
              flex-direction: column;
              display: flex;
              flex: 1;
            }
            .text-small {
              font-size: ${FONT_SIZE.SECONDARY};
              color: ${COLOR.DANGER};
              font-weight: 500;
            }
            .button-delete {
              margin-top: 10px;
            }
            .text-delete {
              max-width: 250px;
              margin-bottom: 10px;
            }
            .delete-popup {
              display: flex;
              flex-direction: column;
              max-width: 350px;
            }
            .cancel-popup {
              position: absolute;
              right: 10px;
              top: 10px;
            }

            .confirmation-actions {
              display: flex;
              flex-direction: column;
            }
          `}</style>
          <div className="delete-popup">
            <span
              role="button"
              className="cancel-popup"
              onClick={() => setIsPopoverOpen(false)}
            >
              <X size={18} />
            </span>
            <div className="text-delete">{description}</div>

            <div className="confirmation-actions">
              <Button
                size="sm"
                variant="danger"
                onClick={async () => {
                  await deleteFunction();
                  setIsPopoverOpen(false);
                }}
              >
                Yes, delete the endpoint
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  setIsPopoverOpen(false);
                }}
                style={{ marginTop: "6px" }}
              >
                No, keep the endpoint
              </Button>
            </div>
          </div>
        </Card>
      }
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        <Button variant="link">
          <Trash2 size={15} color={COLOR.TEXT_PRIMARY} />
        </Button>
      </div>
    </Popover>
  );
}

export { ButtonDeleteWithConfirm };
