import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import React from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const Badge = ({ children, ...rest }: Props) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          display: inline-flex;
          border-radius: ${BORDER_RADIUS.SM};
          background-color: #00b8d4;
          color: ${COLOR.TEXT_LIGHT};
          font-size: ${FONT_SIZE.XXS};
          font-weight: 500;
          text-transform: uppercase;

          line-height: 1;
          text-align: center;
          white-space: nowrap;
          vertical-align: baseline;
          margin-left: 5px;
          padding: 3px 5px 4px;
        }
      `}</style>
      <div className="wrapper" {...rest}>
        {children}
      </div>
    </>
  );
};
