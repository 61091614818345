export const routes = {
  root: "/",

  // dashboard
  dashboard: "/dashboard",
  dashboardSubscription: "/dashboard/subscription",
  dashboardSubscriptionPlans: "/dashboard/subscription/plans",
  subscriptionLogs: `/dashboard/subscription/logs`,
  logsDownload: (id: string) => `/api/logs/${id}/download`,
  billingPortal: "/api/customer-portal",
  dashboardWebhooksAdd: "/dashboard/webhooks/add",
  dashboardWebhook: (id: string) => `/dashboard/webhooks/${id}`,
  workspaces: "/dashboard/workspaces",
  settings: "/dashboard/settings",
  workspace: (id: string) => `/dashboard/workspaces/${id}`,
  workspaceTransferOwnership: (id: string) =>
    `/dashboard/workspaces/${id}/transfer`,
  workspaceBillingPortal: (id: string) =>
    `/api/workspaces/${id}/billing-portal`,
  workspaceInviteLink: (token: string) => `/workspace-invite/${token}`,
  dashboardProject: (id: string) => `/dashboard/project/${id}`,
  projectSettings: (id: string) => `/dashboard/project/${id}/settings`,
  ppuNewOrder: `/dashboard/subscription/ppu/order`,
  ppuActiveOrder: (orderId: string) =>
    `/dashboard/subscription/ppu/order/${orderId}`,
  ppuOrderHistory: `/dashboard/subscription/ppu/history`,

  // auth
  authSignIn: "/auth/signin",
  authSignOut: "/auth/signout",
  authError: "/auth/error",
  authVerify: "/auth/verify-request",
} as const;

export const matchRoute = (path: string, routePattern: string | string[]) => {
  const routePatternsArray = Array.isArray(routePattern)
    ? routePattern
    : [routePattern];
  const cleanPath = path.split("#")[0].split("?")[0];

  for (const pattern of routePatternsArray) {
    if (cleanPath.startsWith(pattern)) {
      return true;
    }
  }

  return false;
};
