import React from "react";
import Skeleton from "react-loading-skeleton";
import { DashboardStatsSkeleton } from "../DashboardStats/DashboardStatsSkeleton";

const DashboardSkeleton = () => {
  return (
    <>
      <Skeleton width={310} height={26} style={{ marginBottom: "12px" }} />
      <DashboardStatsSkeleton />
    </>
  );
};

export default DashboardSkeleton;
