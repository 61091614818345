import { COLOR } from "constants/styles";
import React from "react";

const SWITCH_BORDER_RADIUS = "6px";
export interface SelectionSwitchOption {
  value: string;
  label: React.ReactNode;
  width?: string;
  animatedGradient?: boolean;
}

interface Props {
  options: SelectionSwitchOption[];
  // TODO: value should be generic extending options type
  value: SelectionSwitchOption;
  onChange: (option: SelectionSwitchOption) => void;
}

export const SelectionSwitch = ({ options, value, onChange }: Props) => {
  return (
    <>
      <style jsx>{`
        .switch-wrapper {
          display: flex;
        }

        .option {
          display: flex;
          padding: 8px 24px;
          cursor: pointer;
          border: 1px solid ${COLOR.BORDER_PRIMARY};
          transition: 0.3s;
          justify-content: center;
        }

        .option-label {
          display: flex;
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
          white-space: nowrap;
        }

        .option-active {
          background: ${COLOR.PRIMARY};
          border-color: ${COLOR.PRIMARY};
        }

        .option-active .option-label {
          color: ${COLOR.TEXT_LIGHT};
        }

        .option:first-of-type {
          border-top-left-radius: ${SWITCH_BORDER_RADIUS};
          border-bottom-left-radius: ${SWITCH_BORDER_RADIUS};
        }

        .option:last-of-type {
          border-top-right-radius: ${SWITCH_BORDER_RADIUS};
          border-bottom-right-radius: ${SWITCH_BORDER_RADIUS};
        }

        .option + .option {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px solid ${COLOR.BORDER_PRIMARY};
        }

        @keyframes ppu-anim {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .animated-gradient {
          animation: ppu-anim 6s linear infinite;
          color: ${COLOR.TEXT_LIGHT};
          background: linear-gradient(
            -45deg,
            #ffa63d,
            #ff3d77,
            #338aff,
            #3cf0c5
          );
          border: 1px solid transparent;
          background-size: 600% 600%;
        }

        .animated-gradient .option-label {
          color: ${COLOR.TEXT_LIGHT};
        }
      `}</style>
      <div className="switch-wrapper">
        {options.map(option => {
          return (
            <div
              key={option.value}
              onClick={() => onChange(option)}
              className={`
                ${
                  option.value === value.value
                    ? "option option-active"
                    : "option"
                }
                ${option.animatedGradient ? "animated-gradient" : ""}
                `}
              style={{
                animationPlayState:
                  value.value === option.value ? "paused" : "running",
                width: option.width ?? undefined,
              }}
            >
              <div className="option-label">{option.label}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
