import React from "react";
import { PredefinedPlan } from "types/Plans";
import { COLOR, FONT_SIZE } from "constants/styles";

export const getPlanColor = (name: Props["planName"]) => {
  if (name === "STARTER") {
    return "#7054D3";
  }

  if (name === "HOBBY") {
    return "#51BCEF";
  }
  if (name === "ENTERPRISE") {
    return "#18191A";
  }

  if (name === "Pay As You Go") {
    return "#dd0a50";
  }

  return "#0A60DD";
};

interface Props {
  planName: PredefinedPlan["name"] | "Pay As You Go";
}

export const PricingPlanHeader = ({ planName }: Props) => {
  return (
    <>
      <style jsx>{`
        .title {
          color: ${COLOR.PRIMARY};
          display: flex;
          justify-content: flex-start;
          font-weight: 500;
          font-size: ${FONT_SIZE.LG};
          text-transform: uppercase;
        }
        .header {
          padding: 20px 24px;
          margin-bottom: 24px;
        }
        .line {
          width: 25px;
          height: 3px;
          margin: 0 0 30px 0;
          background: ${getPlanColor(planName)};
        }
      `}</style>
      <div className="header">
        <div className="title">{planName}</div>
        <div className="line"></div>
      </div>
    </>
  );
};
