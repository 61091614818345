import { COLOR, FONT_SIZE } from "constants/styles";
import React from "react";

type Props = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

export const Label = ({ children, ...rest }: Props) => {
  return (
    <>
      <label className="label" {...rest}>
        {children}
      </label>

      <style jsx>{`
        .label {
          text-transform: uppercase;
          font-size: ${FONT_SIZE.SECONDARY};
          letter-spacing: 0.5px;
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
          white-space: nowrap;
        }
      `}</style>
    </>
  );
};
