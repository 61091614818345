import axios, { AxiosError } from "axios";
import useSWR, { mutate } from "swr";
import { WorkspaceProject, WorkspaceWebhook } from "types/workspaces";
import { WebhookDefinition, WebhookFormData } from "types/webhooks";
import { useSession } from "next-auth/react";

export const useWorkspaceWebhooks = (workspaceId: string) => {
  const { data: session } = useSession();
  const baseKey = `/api/workspaces/${workspaceId}/webhooks`;

  const { data, error } = useSWR(
    session ? baseKey : null,
    () => axios.get<WorkspaceWebhook[]>(baseKey),
    {
      revalidateOnFocus: true,
    }
  );

  return {
    webhooks: data?.data,
    isWebhooksLoading: !error && !data,
    isWebhooksError: error as Error | AxiosError<WorkspaceProject>,
  };
};

export async function addWorkspaceWebhook(webhookForm: WebhookFormData) {
  const key = `/api/workspaces/${webhookForm.workspace_id}/webhooks`;

  const res = await axios.post<WebhookDefinition>(key, webhookForm);
  mutate(key);
  return res.data;
}

export const updateWorkspaceWebhook = async (
  id: string,
  webhookForm: WebhookFormData
) => {
  const key = `/api/workspaces/${webhookForm.workspace_id}/webhooks`;
  const res = await axios.put(`${key}/${id}`, webhookForm);
  mutate(key);
  return res.data;
};

export const deleteWorkspaceWebhook = async (
  workspaceId: string,
  id: string
) => {
  const key = `/api/workspaces/${workspaceId}/webhooks`;
  await axios.delete(`${key}/${id}`);
  mutate(key);
};
