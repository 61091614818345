import React from "react";
import { toSvg } from "jdenticon";
import { toHexColor } from "utils/colors";
import { COLOR } from "constants/styles";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  workspaceId: string;
  size?: number;
}

const linearScaler = (size: number) => (factor: number) => {
  return Math.floor(size * factor);
};

const WorkspaceAvatar = ({ workspaceId, size = 50, style, ...rest }: Props) => {
  const randomColor = toHexColor(workspaceId);
  const scaleSize = linearScaler(size);

  const svgString = toSvg(workspaceId, size);

  return (
    <>
      <div
        className="image-wrapper"
        style={{ background: randomColor, ...style }}
        // style={{ background: invertHex(randomColor), ...style }}
        {...rest}
      >
        <div dangerouslySetInnerHTML={{ __html: svgString }} />
        <div className="glow" />
        {/* <div className="circle" style={{ background: randomColor }}></div> */}
      </div>

      <style jsx>{`
        .image-wrapper {
          position: relative;
          display: flex;
          flex: 0 0 auto;
          justify-content: center;
          align-items: center;
          width: ${size}px;
          height: ${size}px;
          border-radius: ${scaleSize(0.12)}px;
          overflow: hidden;
        }

        .glow {
          width: ${scaleSize(1.6)}px;
          height: ${scaleSize(1.6)}px;
          border-radius: 50%;
          background: ${COLOR.BG_WHITE};
          opacity: 0.2;
          position: absolute;
          left: -${scaleSize(0.9)}px;
          top: -${scaleSize(0.9)}px;
        }

        .circle {
          width: ${scaleSize(0.6)}px;
          height: ${scaleSize(0.6)}px;
          border-radius: ${scaleSize(0.06)}px;
        }
      `}</style>
    </>
  );
};

export default WorkspaceAvatar;
