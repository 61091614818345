export const QUOTA_USAGE_WARNING_THRESHOLD = 0.8;
// Amount of requests left for triggering low quota notification
export const QUOTA_USAGE_PPU_REQUESTS_WARNING_THRESHOLD = 1000;
export const VERCEL_CRON_KEY = "fGcYyJNBaM";

// Number of most recent requests to calculate success ratio
// Webhooks that have less than this number of requests won't be flagged
export const STALE_WEBHOOK_RECENT_REQUEST_COUNT = 50;
// Webhooks with smaller success ratio will be flagged
export const STALE_WEBHOOK_MIN_SUCCESS_RATIO = 0.1;
