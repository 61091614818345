import React from "react";
import * as ApiTypes from "types/api/history";
import { useGraphData } from "hooks/useGraphData";
import {
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Line,
} from "recharts";
import { format } from "date-fns";
import { abbreviateNumber } from "utils/projects";
import { ProjectBox } from "components/index";
import { COLOR } from "constants/styles";

const LINE_SHOW_LIMIT_PERCENTAGE = 90;

const isLineVisible = (
  requestsLimit: number,
  graphData?: ApiTypes.HistoryData
) => {
  if (!graphData) return false;

  if (graphData.max === 0) {
    return false;
  }

  const maxPercentage = (graphData.max / requestsLimit) * 100;

  if (maxPercentage > LINE_SHOW_LIMIT_PERCENTAGE) {
    return true;
  }
};

interface Props {
  projectUUID: string;
  requestsLimit: number;
}

function HistoryGraph({ projectUUID, requestsLimit }: Props) {
  const { graphData, isGraphDataError, isGraphDataLoading } =
    useGraphData(projectUUID);

  const line = isLineVisible(requestsLimit, graphData);
  const domainMax = line
    ? Math.max(requestsLimit, graphData?.max ?? 0)
    : "auto";

  return (
    <ProjectBox
      title="Requests Last 30 days"
      description="Number of requests per day for the last 30 days."
    >
      {isGraphDataError && !isGraphDataLoading && (
        <div className="error-wrapper">Error while fetching the graph data</div>
      )}
      <div
        className={graphData && graphData.data ? "" : "blur"}
        style={{
          width: "100%",
          height: "170px",
        }}
      >
        {!isGraphDataLoading && !isGraphDataError && graphData?.data && (
          <ResponsiveContainer>
            <ComposedChart
              data={graphData.data}
              margin={{
                left: 10,
              }}
            >
              <XAxis
                dataKey="time"
                minTickGap={10}
                allowDecimals={false}
                dy={5}
                axisLine={false}
                domain={[0, "dataMax"]}
                tickLine={false}
                tick={{ fill: COLOR.TEXT_SECONDARY }}
                tickFormatter={tick =>
                  format(new Date(tick), "MMM d").toUpperCase()
                }
                style={{
                  fontFamily: "Rubik",
                  fontSize: 10,
                }}
              />
              <YAxis
                type="number"
                width={35}
                tickLine={false}
                domain={[0, domainMax]}
                allowDecimals={false}
                axisLine={false}
                tick={{ fill: COLOR.TEXT_SECONDARY }}
                style={{
                  fontFamily: "Rubik",
                  fontSize: 10,
                  marginRight: 10,
                }}
                dx={-10}
                tickFormatter={tick => abbreviateNumber(tick)}
              />
              <CartesianGrid horizontal vertical={false} stroke="#EBEEF3" />
              <Bar
                minPointSize={1}
                dataKey="requestCount"
                fill="#6AB3F9"
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dot={false}
                isAnimationActive={false}
                activeDot={false}
                dataKey={() => {
                  if (line) {
                    return requestsLimit;
                  }

                  return undefined;
                }}
                stroke="#FF0000"
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
      <style jsx>{`
        .error-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      `}</style>
    </ProjectBox>
  );
}

export { HistoryGraph };
