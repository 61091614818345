import React from "react";

export const ContactSupportLink = () => {
  return (
    <>
      <style jsx>{`
        .alert-link {
          cursor: pointer;
          font-weight: 500;
          text-decoration: underline !important;
        }
      `}</style>

      <a
        onClick={() => {
          window.$zopim.livechat.window.show();
        }}
        className="alert-link"
      >
        contact our support
      </a>
    </>
  );
};

export default ContactSupportLink;
