import React, { memo } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { History } from "types/api/projects";
import { scaleQuantize } from "d3-scale";
import { LockedOrEmptyBox, Card } from "components";
import { COLOR, FONT_SIZE } from "constants/styles";

const geoUrl = "/json/geo-url.json";

const WorldMap = ({
  data,
  setTooltipContent,
  isLocked,
}: {
  data?: History["countries"];
  setTooltipContent: (content: string) => unknown;
  isLocked?: boolean;
}) => {
  if (isLocked || (data && data.length === 0)) {
    return <LockedOrEmptyBox isLocked={isLocked} name="locations" />;
  }

  if (!data) return null;

  const colorScale = scaleQuantize()
    .domain(data.map(d => Number(d.requests)))
    // @ts-expect-error this works omg
    .range(["#69B2F8", "#188efc"]);

  return (
    <>
      <style jsx>{`
        .title-heading {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .title {
          text-transform: uppercase;
          font-size: ${FONT_SIZE.XS};
          letter-spacing: 0.5px;
          font-weight: 600;
          color: ${COLOR.TEXT_PRIMARY};
        }

        .top-text {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
        }
      `}</style>
      <Card>
        <div className="title-heading">
          <h6 className="title">Locations</h6>
          <h5 className="top-text">TOP 10</h5>
        </div>
        <ComposableMap data-tip="">
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => {
                const d = data.find(
                  item => item.user_country === geo.properties.ISO_A2
                );

                return (
                  <Geography
                    onMouseEnter={() => {
                      if (!d?.requests) return null;
                      setTooltipContent(
                        `${geo.properties.NAME} — ${d?.requests} requests`
                      );
                    }}
                    onMouseLeave={() => {
                      setTooltipContent("");
                    }}
                    key={geo.rsmKey}
                    geography={geo}
                    // @ts-expect-error this works omg
                    fill={d ? colorScale(Number(d.requests)) : "#f4f5f8"}
                    style={{
                      default: {
                        outline: "none",
                      },
                      hover: {
                        outline: "none",
                      },
                      pressed: {
                        outline: "none",
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </Card>
    </>
  );
};

export default memo(WorldMap);
