import { COLOR } from "constants/styles";
import Link, { LinkProps } from "next/link";
import React from "react";

interface Props {
  title: React.ReactNode;
  href?: string;
}

interface OptionalLinkProps extends Omit<LinkProps, "href"> {
  children: React.ReactNode;
  href: LinkProps["href"] | undefined;
}

const OptionalLink = ({ href, children }: OptionalLinkProps) => {
  if (href) {
    return (
      <>
        <style jsx>{`
          .link-light {
            width: 100%;
            color: ${COLOR.TEXT_LIGHT};
            font-weight: 500;
            transition: 0.3s;
          }
          .link-light:hover,
          .link-light:active {
            color: ${COLOR.TEXT_LIGHT};
            font-weight: 500;
          }
        `}</style>
        <Link href={href} passHref legacyBehavior>
          <a className="link-light" target="_blank">
            {children}
          </a>
        </Link>
      </>
    );
  } else {
    return <>{children}</>;
  }
};

export const Announcement = ({ title, href }: Props) => {
  return (
    <OptionalLink href={href}>
      <div className="announcement-panel">
        <div className="title">{title}</div>
        <style jsx>{`
          .announcement-panel {
            display: flex;
            justify-content: center;
            background-color: ${COLOR.BG_DARKER};
            color: ${COLOR.TEXT_LIGHT};
            width: 100%;
            padding: 12px 16px;
            height: 100%;
            text-align: center;
          }

          .title {
            font-weight: 400;
          }
        `}</style>
      </div>
    </OptionalLink>
  );
};
