import React from "react";
import Skeleton from "react-loading-skeleton";
import Link from "next/link";
import { useSession } from "next-auth/react";
import { getFormattedPlanPrice } from "utils/formatting";
import { BillingCycle, PredefinedPlan } from "types/Plans";
import PlanFeatures from "../PlanFeatures";
import { Button } from "components";
import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";
import { routes } from "utils/routes";
import { PricingPlanHeader } from "./PricingPlanHeader";

const getPlanAction = (plan: PredefinedPlan) => {
  if (plan.name === "STARTER") {
    return "Build Apps Now";
  }

  if (plan.name === "HOBBY") {
    return "Get Your API Key";
  }

  if (plan.name === "ENTERPRISE") {
    return "Get in Touch";
  }

  return "Get Your API Key";
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  predefinedPlan: PredefinedPlan;
  billingCycle: BillingCycle;
  customButtons?: React.ReactNode;
}

export const PricingPlan = ({
  predefinedPlan,
  billingCycle,
  customButtons,
  ...rest
}: Props) => {
  const { data: session, status } = useSession();
  const loading = status === "loading";
  const plan = predefinedPlan;

  return (
    <div className="wrapper" {...rest}>
      <style jsx>{`
        .wrapper {
          width: 100%;
          border: 1px solid #ececec;
          border-radius: ${BORDER_RADIUS.XL};
          background-color: ${COLOR.BG_WHITE};
          box-shadow: 0 0 20px rgba(84, 35, 199, 0.1);
          overflow: hidden;
        }

        .price {
          font-family: "Rubik", sans-serif;
          color: ${COLOR.PRIMARY};
          font-weight: 400;
          padding: 20px 0;
          font-size: ${FONT_SIZE.XXL};
          text-align: center;
          margin-bottom: 20px;
        }
        .desc {
          color: ${COLOR.TEXT_SECONDARY};
          font-size: ${FONT_SIZE.SECONDARY};
        }
        .buttons {
          display: flex;
          justify-content: center;
          margin: 24px;
          height: 38px;
        }
        .features-padding {
          padding: 0px 24px;
        }

        .discount-wrapper {
          position: relative;
          display: flex;
          text-align: center;
        }

        .discount {
          position: absolute;
          top: -36px;
          width: 100%;
          padding: 8px 16px;
          background: ${COLOR.BG_PURPLE};
          color: ${COLOR.TEXT_LIGHT};
          font-size: ${FONT_SIZE.PRIMARY};
          font-weight: 500;
        }

        .discount-badge-wrapper {
          position: relative;
          display: flex;
          justify-content: center;
        }

        .discount-badge {
          position: absolute;
          display: flex;
          color: #13ac2d;
          font-size: ${FONT_SIZE.LG};
          font-weight: 500;
          white-space: nowrap;
        }
      `}</style>
      <PricingPlanHeader planName={predefinedPlan.name} />

      {(predefinedPlan.name === "HOBBY" ||
        predefinedPlan.name === "DEVELOPER") && (
        <div className="discount-wrapper">
          <div className="discount">Get 15% off with yearly plan</div>
        </div>
      )}
      <div className="price">
        {getFormattedPlanPrice(plan, billingCycle)}
        <div className="desc" style={{ minHeight: 25 }}>
          <span>
            {plan.name === "STARTER" && `😈\u00A0 till hell freezes over`}
          </span>
          {(plan.name === "HOBBY" || plan.name === "DEVELOPER") && (
            <span>
              {billingCycle === "month"
                ? "per month (excl. VAT)"
                : "per year (excl. VAT)"}
            </span>
          )}
          {plan.name === "ENTERPRISE" && (
            <span>{billingCycle === "month" ? "per month" : "per year"}</span>
          )}
        </div>
        <div className="discount-badge-wrapper">
          {predefinedPlan.name === "HOBBY" && billingCycle === "year" && (
            <div className="discount-badge">Save €49</div>
          )}
          {predefinedPlan.name === "DEVELOPER" && billingCycle === "year" && (
            <div className="discount-badge">Save €149</div>
          )}
        </div>
      </div>

      <div className="features-padding">
        <PlanFeatures plan={plan} />
      </div>

      {customButtons ? (
        <div className="buttons">{customButtons}</div>
      ) : (
        <div className="buttons">
          {loading && <Skeleton width={200} height={33} />}
          {!loading && (
            <>
              {plan.name !== "ENTERPRISE" ? (
                <Link
                  href={
                    session
                      ? routes.dashboardSubscription
                      : {
                          pathname: routes.authSignIn,
                          query: {
                            redirect: routes.dashboardSubscription,
                          },
                        }
                  }
                  style={{ width: "100%" }}
                >
                  <Button variant="outline-primary" fullWidth>
                    {getPlanAction(plan)}
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="outline-primary"
                  fullWidth
                  onClick={() => window.$zopim.livechat.window.show()}
                >
                  {getPlanAction(plan)}
                </Button>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
