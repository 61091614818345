import { TRIGGER_OPTIONS, TRIGGER_TYPE_OPTIONS } from "constants/webhooks";
import {
  WebhookConditionFinal,
  WebhookConditionForm,
  WebhookDefinition,
  WebhookFormData,
  WebhookFormDataRaw,
} from "types/webhooks";
import networks from "constants/networks";

export const transformConditionsToBackendFormat = (
  conditions: WebhookConditionForm[]
): WebhookConditionFinal[] => {
  const transformedConditions = conditions.map(condition => ({
    operator: condition.operator.value,
    type: condition.type.value,
    value: condition.value,
    selector: condition.selector,
  }));
  return transformedConditions;
};

export const transformConditionsToFormFormat = (
  webhook: WebhookDefinition
): WebhookConditionForm[] => {
  const transformedConditions = webhook.conditions.map(condition => ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    operator: TRIGGER_TYPE_OPTIONS[webhook.type]
      .find(t => t.value === condition.type)!
      .operators.find(o => o.value === condition.operator)!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    type: TRIGGER_TYPE_OPTIONS[webhook.type].find(
      t => t.value === condition.type
    )!,
    value: condition.value,
    selector: condition.selector,
  }));
  return transformedConditions;
};

export const transformWebhookFormDataToBackendFormat = (
  data: WebhookFormDataRaw
): WebhookFormData => {
  if (data.network.webhookNetworkId === undefined) {
    throw Error(
      `Cardano network ${data.network.label} is not supported by Secure Webhooks`
    );
  }
  const transformedFormData: WebhookFormData = {
    ...data,
    network: data.network.webhookNetworkId,
    api_version: 1,
    type: data.trigger.value,
    confirmations: Number(data.confirmations),
    status: data.status ? ("enabled" as const) : ("disabled" as const),
    conditions: transformConditionsToBackendFormat(data.conditions ?? []),
  };

  return transformedFormData;
};

export const getWebhookFormDefaultValues = (
  webhook: WebhookDefinition | undefined
) => {
  const transformedConditions = webhook
    ? transformConditionsToFormFormat(webhook)
    : undefined;
  return webhook
    ? {
        name: webhook.name,
        description: webhook.description,
        network: networks.find(n => n.webhookNetworkId === webhook.network),
        status: webhook.status === "enabled" ? true : false,
        trigger: TRIGGER_OPTIONS.find(o => o.value === webhook.type),
        url: webhook.target_url,
        confirmations: webhook.confirmations,
        conditions: transformedConditions,
      }
    : {
        name: undefined,
        description: undefined,
        network: networks[0],
        status: true,
        trigger: TRIGGER_OPTIONS[0],
        url: undefined,
        confirmations: 0,
        conditions: [],
      };
};
