import { bech32 } from "bech32";

export const isAddressValid = (address: string) => {
  try {
    bech32.decode(address, 1000);
    return true;
  } catch {
    return false;
  }
};
