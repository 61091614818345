import React from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  size?: "xs" | "sm" | "normal";
}

export const Spinner = ({ size = "normal", ...rest }: Props) => {
  return (
    <>
      <style jsx>{`
        @keyframes spinner-border {
          to {
            transform: rotate(360deg);
          }
        }

        .spinner-border {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          vertical-align: text-bottom;
          border: 0.25em solid currentColor;
          border-right-color: transparent;
          border-radius: 50%;
          animation: spinner-border 0.75s linear infinite;
        }

        .spinner-border-normal {
          width: 2rem;
          height: 2rem;
        }

        .spinner-border-sm {
          width: 1rem;
          height: 1rem;
          border-width: 0.2em;
        }

        .spinner-border-xs {
          width: 12px;
          height: 12px;
          border-width: 2px;
        }

        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border: 0;
        }
      `}</style>
      <div className={`spinner-border spinner-border-${size}`} {...rest}>
        <span className="sr-only">Loading...</span>
      </div>
    </>
  );
};
