import React from "react";
import ContactSupportLink from "components/UI/ContactSupportLink";
import { useCustomer } from "hooks/useCustomer";
import { Alert } from "components";
import { useSubscriptions } from "hooks/useSubscriptions";
import { useWorkspaceBilling } from "hooks/useWorkspaceBilling";
import { useWorkspace } from "hooks/useWorkspaces";
import { routes } from "utils/routes";
import { ROLE_PERMISSIONS } from "constants/rbac";
import UsageCloseToLimitsNotification from "./UsageCloseToLimits";
import RequestsBoostActive from "./RequestsBoostActive";

interface Props {
  workspaceId: string;
}
export const Notifications = ({ workspaceId }: Props) => {
  const { customer } = useCustomer({ migrate: false });
  const { enhancedSubscription } = useSubscriptions();
  const { workspace } = useWorkspace(workspaceId);
  const { billing } = useWorkspaceBilling(workspaceId);

  const permissionToManageBilling = workspace
    ? !!ROLE_PERMISSIONS.workspace.manage_billing[workspace.role_name]
    : false;

  return (
    <>
      <style jsx>{`
        .notifications-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      `}</style>
      <div className="notifications-wrapper">
        {enhancedSubscription?.status === "incomplete_expired" && (
          <Alert
            variant="warning"
            cta={{
              children: "Manage Billing",
              href: routes.billingPortal,
            }}
          >
            <span>
              The initial payment for your subscription failed and no successful
              payment was made within 23 hours of creating the subscription.
              Access to Blockfrost has been restricted. To resolve the issue
              please&nbsp;
            </span>
            <ContactSupportLink />
            <span>.</span>
          </Alert>
        )}
        {/* looks like incomplete_expired are not returned by the API, but let's keep it just to be sure */}
        {enhancedSubscription?.status !== "incomplete_expired" &&
          customer?.delinquent && (
            <Alert
              variant="primary"
              cta={{
                children: "Manage Billing",
                href: routes.billingPortal,
              }}
            >
              <span>
                You seem to have an outstanding invoice. To see the invoice,
                change your payment method or to make a payment please proceed
                to our Billing Portal. If you have any questions please&nbsp;
              </span>
              <ContactSupportLink />
              <span>.</span>
            </Alert>
          )}

        {/* Notification for every workspace members that the workspace has pending invoice */}
        {workspace && billing?.delinquent && !workspace.is_creator && (
          <Alert
            variant="primary"
            cta={
              permissionToManageBilling
                ? {
                    href: routes.workspaceBillingPortal(workspaceId),
                    children: "Manage Billing",
                  }
                : undefined
            }
          >
            <span>
              <span>Owner of this workspace </span>
              <strong>{workspace.creator_email}</strong>
              <span>
                &nbsp;seems to have an outstanding invoice. To keep the
                workspace&apos;s projects running without any interruption,
                please ensure that the billing information for the workspace
                owner is updated promptly. If you have any questions
                please&nbsp;
              </span>
            </span>
            <ContactSupportLink />
            <span>.</span>
          </Alert>
        )}
        {workspace && <UsageCloseToLimitsNotification workspace={workspace} />}
        {workspace && <RequestsBoostActive workspace={workspace} />}
      </div>
    </>
  );
};

export default Notifications;
