import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { X } from "react-feather";
import { Popover } from "react-tiny-popover";
import { Button, Card } from "components";
import { COLOR, FONT_SIZE } from "constants/styles";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  onRemove: () => Promise<void>;
  btnLabel?: React.ReactNode;
  confirmBtnLabel?: React.ReactNode;
  warningMessage?: React.ReactNode;
  confirmationString: string;
  checkboxMessage?: React.ReactNode;
  children?: React.ReactNode;
  cypressId?: string; // used for e22 in data-cy attribute
}

const DeleteButtonWithConfirmation = ({
  children,
  onRemove,
  confirmationString,
  btnLabel,
  confirmBtnLabel,
  warningMessage,
  checkboxMessage,
  cypressId,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const { register, handleSubmit, watch, formState } = useForm();

  const checkboxToggled = watch("confirm-checkbox");
  const isDeleteBtnDisabled = Boolean(
    formState.isSubmitting ||
      (checkboxMessage && !checkboxToggled) ||
      watch("resourceName") !== confirmationString.trim()
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["bottom"]}
      align="end"
      padding={10}
      containerStyle={{ maxWidth: "350px" }}
      content={
        <Card>
          <style jsx>{`
            .header-wrapper {
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
            }
            .text-small {
              font-size: ${FONT_SIZE.SECONDARY};
              color: ${COLOR.DANGER};
              font-weight: 500;
            }

            .text-delete {
              max-width: 250px;
              margin-bottom: 10px;
            }
            .delete-popup {
              padding: 5px 0px;
            }
            .cancel-popup {
              position: absolute;
              right: 10px;
              top: 10px;
            }

            .cta-wrapper {
              margin-top: 20px;
            }

            .b-uppercase {
              text-transform: uppercase;
            }

            .checkbox-wrapper {
              display: flex;
              margin-top: 0.5rem;
              align-items: flex-start;
            }

            #confirm-checkbox {
              margin-top: 4px;
            }

            .checkbox-label {
              margin-left: 8px;
            }
          `}</style>
          <div className="delete-popup">
            <span
              role="button"
              className="cancel-popup"
              onClick={() => setIsPopoverOpen(false)}
            >
              <X size={18} style={{ cursor: "pointer" }} />
            </span>
            <div className="text-delete">
              <span>
                {warningMessage ?? "This will permanently delete the resource."}
              </span>
              <span> Please type</span> <b>{confirmationString}</b>
              <span>&nbsp;to confirm.</span>
            </div>
            <form
              className="wasValidated"
              onSubmit={handleSubmit(async () => {
                await onRemove();
                setIsPopoverOpen(false);
              })}
            >
              <input
                autoComplete="off"
                className="form-control"
                {...register("resourceName", {
                  required: "Input is empty",
                })}
              />

              {checkboxMessage && (
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="confirm-checkbox"
                    {...register("confirm-checkbox")}
                  />
                  <label htmlFor="confirm-checkbox" className="checkbox-label">
                    <span className="text-small">{checkboxMessage}</span>
                  </label>
                </div>
              )}

              <div className="cta-wrapper">
                <Button
                  role="button"
                  size="xs"
                  fullWidth
                  variant={isDeleteBtnDisabled ? "outline-secondary" : "danger"}
                  type="submit"
                  disabled={isDeleteBtnDisabled}
                  loading={formState.isSubmitting}
                  data-cy={
                    cypressId ? `${cypressId}-delete-btn-confirm` : undefined
                  }
                >
                  <span className="b-uppercase">
                    {confirmBtnLabel ?? "Delete permanently"}
                  </span>
                </Button>
              </div>
            </form>
          </div>
        </Card>
      }
    >
      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
        {children ? (
          children
        ) : (
          <Button
            variant="danger"
            size="xs"
            uppercase
            data-cy={cypressId ? `${cypressId}-delete-btn` : undefined}
          >
            {btnLabel}
          </Button>
        )}
      </div>
    </Popover>
  );
};

export { DeleteButtonWithConfirmation };
