import { SubscriptionStats } from "hooks/useSubscriptionStats";
import { BillingCycle, PredefinedPlan } from "types/Plans";
import { STARTER_PLAN } from "constants/plans";
import { differenceInHours, format, parseISO } from "date-fns";
import { COLOR } from "constants/styles";
import { WorkspaceWebhook } from "types/workspaces";
import BigNumber from "bignumber.js";

export const formatNumber = (num: number) => {
  const locale =
    typeof navigator !== "undefined" ? navigator.language : "en-US";
  return new Intl.NumberFormat(locale).format(num);
};

export const formatPrice = (price: number, currency: string) => {
  const locale =
    typeof navigator !== "undefined" ? navigator.language : "en-US";

  let formattedPrice;

  try {
    formattedPrice = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      currencyDisplay: "narrowSymbol",
    }).format(price);
  } catch (error) {
    // Safari < 14.1 doesn't support narrowSymbol https://github.com/mdn/browser-compat-data/issues/8985
    console.warn(`Failed to format price.`, error);
    formattedPrice = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(price);
  }

  return formattedPrice;
};

export const getFormattedPlanPrice = (
  plan: PredefinedPlan,
  billingCycle: BillingCycle
) => {
  const priceObj = getPriceForBillingCycle(plan.stripePriceId, billingCycle);
  if (plan.name === "ENTERPRISE") {
    return "🦄 Custom";
  }

  if (plan.name === "STARTER") {
    return "FREE";
  }

  if (priceObj) {
    return formatPrice(priceObj.price, priceObj.price_currency);
  }
};

export const normalizeProjectId = (
  input?: string | undefined | string[]
): string | undefined => {
  if (Array.isArray(input)) return undefined;

  const stringsToRemove = [
    "ipfs",
    "mainnet",
    "testnet",
    "milktestnet",
    "milkmainnet",
    "preview",
    "preprod",
  ];
  let result = input;

  for (const item of stringsToRemove) {
    if (!input) continue;

    if (input.startsWith(item)) {
      result = input.slice(item.length);
    }
  }

  return result;
};

export const getDowngradeWarningMessage = (params: {
  stats: Pick<
    SubscriptionStats["used"],
    "projectCount" | "webhookCount" | "workspaceCount"
  >;
  customerHasUnpaidInvoice: boolean;
  newPlan?: PredefinedPlan;
}) => {
  const { stats, customerHasUnpaidInvoice } = params;
  const plan = params.newPlan ?? STARTER_PLAN;

  if (customerHasUnpaidInvoice) {
    return `You cannot ${
      plan.name === "STARTER" ? "cancel" : "change"
    } your subscription while having an unpaid invoice.`;
  }
  const unitsExceedingFreePlan = {
    projects: stats.projectCount - plan.projectCount,
    webhooks: stats.webhookCount - plan.webhookCount,
    workspaceCount: stats.workspaceCount - plan.workspaceCount,
  };

  if (
    unitsExceedingFreePlan.projects <= 0 &&
    unitsExceedingFreePlan.webhooks <= 0 &&
    unitsExceedingFreePlan.workspaceCount <= 0
  ) {
    return undefined;
  }

  const projectsPart =
    unitsExceedingFreePlan.projects > 0
      ? `${unitsExceedingFreePlan.projects} project${
          unitsExceedingFreePlan.projects === 1 ? "" : "s"
        }`
      : undefined;

  const webhookPart =
    unitsExceedingFreePlan.webhooks > 0
      ? `${unitsExceedingFreePlan.webhooks} webhook${
          unitsExceedingFreePlan.webhooks === 1 ? "" : "s"
        }`
      : undefined;

  const workspacePart =
    unitsExceedingFreePlan.workspaceCount > 0
      ? `${unitsExceedingFreePlan.workspaceCount} workspace${
          unitsExceedingFreePlan.workspaceCount === 1 ? "" : "s"
        }`
      : undefined;

  const toJoin = [projectsPart, webhookPart, workspacePart].filter(
    p => p !== undefined
  ) as string[];

  let joined = "";
  if (toJoin.length === 1) {
    joined = toJoin[0];
  } else if (toJoin.length === 2) {
    joined = toJoin.join(" and ");
  } else if (toJoin.length === 3) {
    joined = toJoin.slice(0, 2).join(", ") + ` and ${toJoin[2]}`;
  }

  const msg = `Please remove ${joined} to match the ${plan.name} plan`;
  return msg;
};

export const getWebhookStatusIndicator = (
  webhook: Pick<WorkspaceWebhook, "status" | "fail_history">,
  showWarningIfFailedInHours = 48
) => {
  if (webhook.status === "disabled") {
    return {
      code: "disabled",
      color: COLOR.BORDER_PRIMARY,
      label: "Disabled",
    };
  } else {
    const lastFailedRequests = webhook.fail_history?.find(h => {
      const diffHours = differenceInHours(new Date(), new Date(h.fired_at));
      return !h.success && diffHours < showWarningIfFailedInHours;
    });
    if (lastFailedRequests) {
      return {
        code: "enabled-with-warning",
        color: COLOR.WARNING,
        label: "Enabled",
        tooltip: `We have detected problems with your endpoint in the past ${showWarningIfFailedInHours} hours. If a webhook fails repeatedly, it may be disabled automatically.`,
      };
    }
    return {
      code: "enabled",
      color: COLOR.SUCCESS,
      label: "Enabled",
    };
  }
};

export const getPriceForBillingCycle = (
  priceObj: PredefinedPlan["stripePriceId"],
  cycle: "month" | "year"
) => {
  return priceObj ? priceObj[cycle] : undefined;
};

export const formatISODate = (dateString: string) => {
  return format(parseISO(dateString), "yyyy-MM-dd HH:mm:ss O");
};

export const numberFormatter = (
  n: number | string,
  formatter?: (num: number | string) => number
) => {
  const safeNumber = typeof n === "string" ? new BigNumber(n).toNumber() : n;
  return formatter
    ? formatNumber(formatter(safeNumber))
    : formatNumber(safeNumber);
};

export const lovelaceToAda = (lovelace: string) =>
  new BigNumber(lovelace).dividedBy(1000000).toString();
