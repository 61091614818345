import { COLOR } from "constants/styles";
import React from "react";

interface Props extends React.LiHTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  active?: boolean;
}

export const PaginationItem = ({ children, active, ...rest }: Props) => {
  return (
    <>
      <li className={`page-item ${active ? "page-item-active" : ""}`} {...rest}>
        <a
          className="page-link"
          href="#"
          onClick={e => {
            e.preventDefault();
          }}
        >
          {children}
        </a>
      </li>
      <style jsx>{`
        .page-item {
          cursor: pointer;
        }

        .page-item-active {
          cursor: auto;
          pointer-events: none;
        }

        .page-link {
          position: relative;
          min-width: 36px;
          height: 100%;
          padding: 9px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${COLOR.PRIMARY};
          transition: all 0.2s ease-in-out;
          margin-left: -1px;
          line-height: 1.25;
          background-color: ${COLOR.BG_WHITE};
          border: 1px solid ${COLOR.BORDER_PRIMARY};
        }

        .page-item-active .page-link {
          background-color: ${COLOR.PRIMARY};
          color: ${COLOR.TEXT_LIGHT};
          border-color: ${COLOR.PRIMARY};
          cursor: auto;
        }

        .page-link:hover,
        .page-link:focus {
          background-color: ${COLOR.BG_PRIMARY};
        }

        .page-link:hover {
          color: ${COLOR.PRIMARY_HOVER};
          text-decoration: none;
          background-color: ${COLOR.BG_PRIMARY};
          border-color: ${COLOR.BORDER_PRIMARY};
        }

        .page-item:first-child .page-link {
          margin-left: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }

        .page-item:last-child .page-link {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      `}</style>
    </>
  );
};
