import { BREAKPOINT, COLOR } from "constants/styles";
import React from "react";

interface Props
  extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, "content"> {
  children: React.ReactNode;
  fluid?: boolean;
  content?: boolean;
  expand?: boolean;
  noBackground?: boolean;
}

export const Container = ({
  children,
  fluid,
  expand,
  content,
  noBackground,
  ...rest
}: Props) => {
  return (
    <div className={`b-container-wrapper ${content ? "b-content" : ""}`}>
      <div
        className={`b-container ${!fluid ? "b-container-fixed" : ""}`}
        {...rest}
      >
        {children}
      </div>
      <style jsx>{`
        .b-container-wrapper {
          display: flex;
          width: 100%;
          flex: 1 1;
          background: ${content && !noBackground
            ? COLOR.BG_LANDING_LIGHT
            : "transparent"};
        }
        .b-container {
          flex: ${expand ? "1 1" : "none"};
          width: 100%;
          /* padding-right: ${content ? "0" : "15px"};
          padding-left: ${content ? "0" : "15px"}; */
          margin-right: auto;
          margin-left: auto;
        }

        .b-content {
          padding: 20px;
        }

        @media (min-width: ${BREAKPOINT.XS}px) {
          .b-container-fixed {
            max-width: 540px;
          }
        }
        @media (min-width: ${BREAKPOINT.SM}px) {
          .b-container-fixed {
            max-width: 720px;
          }
        }
        @media (min-width: ${BREAKPOINT.MD}px) {
          .b-container-fixed {
            max-width: 960px;
          }

          .b-content {
            padding: 24px 25px;
          }
        }
        @media (min-width: ${BREAKPOINT.LG}px) {
          .b-container-fixed {
            max-width: 1140px;
          }

          .b-content {
            padding: 24px 30px;
          }
        }
      `}</style>
    </div>
  );
};
